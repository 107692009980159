<template>
    <div v-if="main.getDeferredPrompt" class="">
        <div
            class="flex py-2 w-full md:w-1/2 mx-auto bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-200 h-[5vh]"
        >
            <div class="flex-grow flex items-center h-full">
                {{ $t("install_super5_now") }}
            </div>
            <div class="flex gap-3 items-center">
                <button
                    @click="install"
                    class="rounded w-20 p-2 bg-green-600 text-xs font-semibold shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 text-white"
                >
                    INSTALL
                </button>
                <button
                    @click="dismiss"
                    class="rounded w-20 p-2 bg-gray-600 text-xs font-semibold shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 text-white"
                >
                    CLOSE
                </button>
            </div>
        </div>
    </div>
    <div
        class="w-full inset-0 md:w-1/2 flex flex-col mx-auto md:border-2 md:border-orange-500 relative"
        :class="{
            'h-screen': !main.getDeferredPrompt,
            'h-[95vh]': main.getDeferredPrompt,
        }"
    >
        <div
            class="flex flex-row w-full justify-between items-center p-2 bg-orange-600 border-b-2 border-orange-600 text-white"
        >
            <button
                class="text-xs font-semibold shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 text-white"
            >
                <img
                    v-if="auth.getUser.avatar"
                    :src="auth.getUser.avatar"
                    :alt="auth.getUser.name"
                    class="rounded-full w-10 h-10"
                />
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="currentColor"
                    class="w-10 h-10 text-gray-100 rounded-full"
                >
                    <path
                        d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                    />
                </svg>
            </button>
            <div class="flex-grow mx-5">
                <div class="font-bold text-xl">{{ auth.getUser.name }}</div>
                <div class="flex flex-row gap-4">
                    <div>
                        {{ $t("credit", { credit: auth.getUser.credit }) }}
                    </div>
                    <div>{{ $t("win", { cash: auth.getUser.cash }) }}</div>
                </div>
            </div>
            <router-link
                :to="{ name: 'record' }"
                class="rounded-full w-12 h-12 p-2 text-gray-100 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                    'bg-green-600 hover:bg-green-700': [
                        'transaction',
                        'record',
                    ].includes(route.name),
                    'bg-purple-600 hover:bg-purple-700': ![
                        'transaction',
                        'record',
                    ].includes(route.name),
                }"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-8 h-8 mx-auto"
                >
                    <path
                        d="M32 132V48c0-8.8-7.2-16-16-16S0 39.2 0 48V176c0 8.8 7.2 16 16 16H144c8.8 0 16-7.2 16-16s-7.2-16-16-16H53.6C89.5 84.3 166.7 32 256 32c123.7 0 224 100.3 224 224s-100.3 224-224 224c-73.3 0-138.3-35.2-179.2-89.6c-5.3-7.1-15.3-8.5-22.4-3.2s-8.5 15.3-3.2 22.4C97.9 471.8 172.2 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C159.6 0 75.7 53.3 32 132zm224-4c-8.8 0-16 7.2-16 16V256c0 4.2 1.7 8.3 4.7 11.3l80 80c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L272 249.4V144c0-8.8-7.2-16-16-16z"
                    />
                </svg>
            </router-link>
        </div>
        <!--        <div-->
        <!--            v-if="-->
        <!--                ['history', 'about', 'guide', 'language'].includes(route.name)-->
        <!--            "-->
        <!--            class="absolute top-[5rem] right-5 z-40"-->
        <!--            :class="{-->
        <!--                'top-28': !main.getDeferredPrompt,-->
        <!--                'top-32': main.getDeferredPrompt,-->
        <!--            }"-->
        <!--        >-->
        <!--            <div class="flex gap-5 flex-row-reverse">-->
        <!--                <router-link-->
        <!--                    :to="{ name: 'menu' }"-->
        <!--                    class="text-xl p-2 block rounded-full bg-red-500"-->
        <!--                >-->
        <!--                    <svg-->
        <!--                        xmlns="http://www.w3.org/2000/svg"-->
        <!--                        height="1em"-->
        <!--                        viewBox="0 0 384 512"-->
        <!--                        fill="currentColor"-->
        <!--                        class="w-6 h-6 text-white"-->
        <!--                    >-->
        <!--                        <path-->
        <!--                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"-->
        <!--                        />-->
        <!--                    </svg>-->
        <!--                </router-link>-->
        <!--            </div>-->
        <!--        </div>-->
        <div
            ref="screen"
            class="px-2 pt-2 w-full flex flex-col overflow-hidden flex-grow overflow-y-auto text-center"
        >
            <slot />
        </div>
        <div
            class="bg-orange-600 w-full flex flex-row items-center justify-around border-t-2 border-orange-500 py-3"
        >
            <router-link
                :to="{ name: 'home' }"
                class="relative bg-purple-600 hover:bg-purple-700 rounded-full p-2 text-gray-100 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <div
                    v-if="route.name === 'home'"
                    class="absolute -top-3 text-center w-full left-0"
                >
                    <div
                        class="w-2 h-2 rounded-full bg-green-500 mx-auto"
                    ></div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    fill="currentColor"
                    class="w-8 h-8 mx-auto"
                >
                    <path
                        d="M303.5 5.7c-9-7.6-22.1-7.6-31.1 0l-264 224c-10.1 8.6-11.3 23.7-2.8 33.8s23.7 11.3 33.8 2.8L64 245.5V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V245.5l24.5 20.8c10.1 8.6 25.3 7.3 33.8-2.8s7.3-25.3-2.8-33.8l-264-224zM112 432V204.8L288 55.5 464 204.8V432c0 17.7-14.3 32-32 32H384V312c0-22.1-17.9-40-40-40H232c-22.1 0-40 17.9-40 40V464H144c-17.7 0-32-14.3-32-32zm128 32V320h96V464H240z"
                    />
                </svg>
            </router-link>
            <router-link
                :to="{ name: 'info' }"
                class="relative bg-purple-600 hover:bg-purple-700 rounded-full p-2 text-gray-100 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <div
                    v-if="route.name === 'info'"
                    class="absolute -top-3 text-center w-full left-0"
                >
                    <div
                        class="w-2 h-2 rounded-full bg-green-500 mx-auto"
                    ></div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-8 h-8 mx-auto"
                >
                    <path
                        d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
                    />
                </svg>
            </router-link>
            <router-link
                :to="{ name: 'play' }"
                class="relative bg-rose-700 hover:bg-rose-800 rounded-full p-3 text-gray-100 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <div
                    v-if="route.name === 'play'"
                    class="absolute -top-3 text-center w-full left-0"
                >
                    <div
                        class="w-2 h-2 rounded-full bg-green-500 mx-auto"
                    ></div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-6 h-6 mx-auto"
                >
                    <path
                        d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144V432c0 44.2 35.8 80 80 80H368c44.2 0 80-35.8 80-80V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"
                    />
                </svg>
            </router-link>
            <router-link
                :to="{ name: 'wallet' }"
                class="relative bg-purple-600 hover:bg-purple-700 rounded-full p-3 text-gray-100 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <div
                    v-if="route.name === 'wallet'"
                    class="absolute -top-3 text-center w-full left-0"
                >
                    <div
                        class="w-2 h-2 rounded-full bg-green-500 mx-auto"
                    ></div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-6 h-6 mx-auto"
                >
                    <path
                        d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64H80c-8.8 0-16-7.2-16-16s7.2-16 16-16H448c17.7 0 32-14.3 32-32s-14.3-32-32-32H64zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                    />
                </svg>
            </router-link>
            <router-link
                :to="{ name: 'menu' }"
                class="relative bg-purple-600 hover:bg-purple-700 rounded-full p-3 text-gray-100 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <div
                    v-if="
                        [
                            'menu',
                            'history',
                            'about',
                            'guide',
                            'language',
                        ].includes(route.name)
                    "
                    class="absolute -top-3 text-center w-full left-0"
                >
                    <div
                        class="w-2 h-2 rounded-full bg-green-500 mx-auto"
                    ></div>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="currentColor"
                    class="w-6 h-6 mx-auto"
                >
                    <path
                        d="M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z"
                    />
                </svg>
            </router-link>
        </div>
        <button
            v-if="auth.getUser.refer"
            @click="shareLink('line')"
            class="absolute bottom-24 right-5 bg-opacity-75 rounded-full bg-pink-500 hover:bg-pink-600 p-2 text-center text-white"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.6em"
                fill="currentColor"
                viewBox="0 0 448 512"
                class="pr-1"
            >
                <path
                    d="M448 112c0 44.2-35.8 80-80 80c-22.9 0-43.6-9.6-58.1-25l-151 75.5c.8 4.4 1.1 8.9 1.1 13.5s-.4 9.1-1.1 13.5l151 75.5c14.6-15.4 35.2-25 58.1-25c44.2 0 80 35.8 80 80s-35.8 80-80 80s-80-35.8-80-80c0-9.7 1.7-19 4.9-27.7L147.2 299.5c-14.3 22-39 36.5-67.2 36.5c-44.2 0-80-35.8-80-80s35.8-80 80-80c28.2 0 52.9 14.5 67.2 36.5l145.7-72.9c-3.2-8.6-4.9-17.9-4.9-27.7c0-44.2 35.8-80 80-80s80 35.8 80 80zM80 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM416 112a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM368 448a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                />
            </svg>
        </button>
    </div>
</template>
<script setup>
import { RouterLink, useRoute } from "vue-router";
import { useAuthStore, useMainStore } from "@/store/index.js";
import { inject, ref } from "vue";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const emitter = inject("emitter");

const auth = useAuthStore();
const main = useMainStore();
const screen = ref();
const route = useRoute();

emitter.on("toTop", () => {
    screen.value.scrollTop = 0;
});

window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    if (
        cookies.get("spr-home") === null ||
        cookies.get("spr-home") === undefined
    ) {
        main.setDeferredPrompt(e);
    }
});
window.addEventListener("appinstalled", () => {
    main.setDeferredPrompt();
    cookies.set("spr-home", true);
});
const dismiss = async () => {
    // cookies.set("spr-home", false);
    main.setDeferredPrompt();
};
const install = async () => {
    main.getDeferredPrompt.prompt();
};
const shareLink = (type = "text") => {
    const textArea = document.createElement("textarea");
    textArea.value =
        process.env.VUE_APP_LINE_LOGIN + "?refer=" + auth.getUser.refer;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    switch (type) {
        case "line":
            window.open(
                "https://social-plugins.line.me/lineit/share?url=" +
                    process.env.VUE_APP_LINE_LOGIN +
                    "?refer=" +
                    auth.getUser.refer
            );
            return;
        case "whatsapp":
            window.open(
                "whatsapp://send?text=" +
                    process.env.VUE_APP_LINE_LOGIN +
                    "?refer=" +
                    auth.getUser.refer
            );
            return;
        case "wechat":
            window.open("weixin://dl/chat");
            return;
        default:
    }
    let message = "Copied";
    main.addMessage({ message: message, success: true });
};
</script>
<style scoped></style>
