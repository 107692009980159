<template>
    <div class="flex flex-col h-full w-full justify-between">
        <div class="flex-grow overflow-hidden overflow-y-auto">
            <div class="sticky top-0">
                <div class="text-2xl font-bold">{{ $t("bet_list") }}</div>
                <div class="my-5 flex">
                    <div class="px-2">{{ $t("bet_amount") }}</div>
                    <input
                        type="number"
                        :placeholder="$t('bet_amount')"
                        disabled
                        readonly
                        class="p-2 rounded text-black w-full disabled:text-white disabled:bg-gray-500 border border-gray-3"
                        :value="admin.getBets ? admin.getBets.total_amount : 0"
                    />
                </div>
                <div class="my-5 flex">
                    <div class="px-2">{{ $t("bet_users") }}</div>
                    <input
                        type="number"
                        :placeholder="$t('bet_users')"
                        disabled
                        readonly
                        class="p-2 rounded text-black w-full disabled:text-white disabled:bg-gray-500 border border-gray-3"
                        :value="admin.getBets ? admin.getBets.total_user : 0"
                    />
                </div>
            </div>
            <div v-if="main.getBets.total > 0" class="text-center font-bold">
                {{ main.getBets.current_page }} / {{ main.getBets.last_page }}
            </div>
            <div class="flex flex-col w-full my-5 relative">
                <div class="flex w-full font-bold text-center">
                    <div class="w-1/3">{{ $t("date") }}</div>
                    <div class="w-1/3">{{ $t("number") }}</div>
                    <div class="w-1/3">{{ $t("amount") }}</div>
                </div>
                <template
                    v-if="
                        admin.getBets.results && admin.getBets.results.total > 0
                    "
                >
                    <div
                        v-for="(bet, key) in admin.getBets.results.data"
                        :key="'bet-' + key"
                        class="flex w-full flex-grow"
                    >
                        <!--suppress AllyHtmlVueInspection -->
                        <div class="w-1/3">
                            {{ main.getDate(bet.created_at) }}
                        </div>
                        <div class="w-1/3">
                            {{ bet.number }}
                        </div>
                        <div class="w-1/3">
                            {{ bet.amount }}
                        </div>
                    </div>
                </template>
                <div v-else class="w-full flex-grow text-red-300 my-2">
                    {{ $t("no_new_bet_record") }}
                </div>
            </div>
        </div>
        <div class="w-full flex gap-2">
            <button
                @click="prevPage"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    invisible: search.page <= 1,
                }"
            >
                <span v-if="search.page > 1">{{ $t("previous") }}</span>
                <span v-else></span>
            </button>
            <button
                v-if="auth.getUser.is_admin"
                @click="sortList"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
            >
                <span v-if="!search.sort || search.sort === 'last'">{{
                    $t("sort_bet_last")
                }}</span>
                <span v-else>{{ $t("sort_bet_first") }}</span>
            </button>
            <button
                @click="nextPage"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    invisible:
                        search.page >=
                        (admin.getBets.results
                            ? admin.getBets.results.last_page
                            : 1),
                }"
            >
                <span
                    v-if="
                        search.page <
                        (admin.getBets.results
                            ? admin.getBets.results.last_page
                            : 1)
                    "
                >
                    {{ $t("next") }}
                </span>
                <span v-else></span>
            </button>
        </div>
    </div>
</template>
<script setup>
import { inject, reactive } from "vue";
import { useAdminStore, useAuthStore, useMainStore } from "@/store/index.js";
const emitter = inject("emitter");
const admin = useAdminStore();
const main = useMainStore();
const auth = useAuthStore();
let search = reactive({
    page: 1,
    sort: "last",
});
admin.loadBet(search);
const nextPage = () => {
    if (search.page < admin.getBets.results.last_page) {
        search.page++;
        admin.loadBet(search);
        emitter.emit("toTop");
    }
};
const prevPage = () => {
    if (search.page > 1) {
        search.page--;
        admin.loadBet(search);
        emitter.emit("toTop");
    }
};

const sortList = () => {
    search.sort = search.sort === "last" ? "first" : "last";
    search.page = 1;
    admin.loadBet(search);
    emitter.emit("toTop");
};
</script>
