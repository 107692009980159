<template>
    <div class="relative h-full">
        <div class="text-2xl font-bold">{{ $t("how_to_play") }}</div>
        <div class="flex flex-col gap-5">
            <div class="mt-5">
                <div class="p-2 bg-orange-500 w-full mb-2">
                    {{ $t("1_click_middle_of_the_button_below") }}
                    <div class="rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            fill="currentColor"
                            class="w-8 h-8 text-gray-50 mx-auto"
                        >
                            <path
                                d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                            />
                        </svg>
                    </div>
                </div>
                <img src="@/assets/menu_guide.png" alt="Menu" />
            </div>
            <div>
                <div class="p-2 bg-orange-500 w-full mb-2">
                    {{ $t("2_insert_the_number_you_want_to_bet") }}
                </div>
                <input
                    type="number"
                    disabled
                    :placeholder="$t('number_to_play')"
                    class="p-2 rounded text-black disabled:bg-white w-full border border-gray-3"
                    v-model="form.number"
                />
            </div>
            <div>
                <div class="p-2 bg-orange-500 w-full mb-2">
                    {{ $t("3_insert_the_credit_you_want_to_bet") }}
                </div>
                <input
                    type="number"
                    disabled
                    :placeholder="$t('amount_to_play')"
                    class="p-2 rounded text-black disabled:bg-white w-full border border-gray-3"
                    v-model="form.amount"
                />
            </div>
            <div>
                <div class="p-2 bg-orange-500 w-full mb-2">
                    {{ $t("4_click_confirm_to_confirm_your_bet") }}
                </div>
                <button
                    class="rounded text-center w-full bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
                >
                    {{ $t("confirm") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from "vue";

const form = reactive({
    number: null,
    amount: null,
});
</script>
