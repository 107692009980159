<template>
    <div class="relative flex flex-col h-full justify-between">
        <div
            v-if="
                type === 'agent' &&
                (auth.getUser.is_dealer || auth.getUser.is_admin)
            "
        >
            <div class="text-2xl font-bold flex justify-between">
                <div>{{ $t("agent") }}</div>
                <button
                    @click="addAgent"
                    class="rounded p-2 text-center bg-green-600 hover:bg-green-700 font-bold text-white"
                >
                    <svg
                        v-if="!showFormAgent"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        class="w-8 h-8 mx-auto"
                    >
                        <path
                            d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM240 352c0 8.8 7.2 16 16 16s16-7.2 16-16V272h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V160c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v80z"
                        />
                    </svg>
                    <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        class="w-8 h-8 mx-auto"
                    >
                        <path
                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232c-13.3 0-24 10.7-24 24s10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H184z"
                        />
                    </svg>
                </button>
            </div>
            <div v-if="showFormAgent" class="flex flex-col gap-5 pt-5 relative">
                <select
                    v-if="auth.getUser.is_admin"
                    type="number"
                    class="p-2 rounded text-black w-full"
                    v-model="form_agent.user_id"
                >
                    <option value="">{{ $t("no") }}</option>
                    <option
                        v-for="(dealer, key) in admin.getDealers"
                        :key="'dealer-' + key"
                        :value="dealer.id"
                    >
                        {{ dealer.name }} ({{ dealer.code }})
                    </option>
                </select>
                <input
                    type="text"
                    :placeholder="$t('agent_code')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_agent.code"
                />
                <input
                    type="number"
                    :placeholder="$t('agent_commission_rate')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_agent.cr"
                />
                <input
                    type="text"
                    :placeholder="$t('agent_name')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_agent.name"
                />
                <input
                    type="text"
                    :placeholder="$t('agent_username')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_agent.username"
                />
                <input
                    type="password"
                    :placeholder="$t('agent_password')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_agent.password"
                />
                <button
                    @click="confirmAgent"
                    class="rounded text-center w-full bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
                >
                    {{ $t("create") }}
                </button>
            </div>
            <div class="my-5 flex">
                <input
                    v-if="!showFormAgent"
                    type="text"
                    :placeholder="$t('search_agent_name')"
                    class="p-2 rounded-l text-black w-full disabled:text-white border border-gray-3"
                    v-model="searchAgent.name"
                    @keyup="filterList('agent')"
                />
            </div>
            <div
                class="flex flex-col font-bold text-center w-full my-5 overflow-hidden overflow-y-auto"
            >
                <div class="flex w-full justify-between">
                    <div class="w-1/5">{{ $t("code") }}</div>
                    <div class="w-1/5">{{ $t("name") }}</div>
                    <div class="w-1/5">{{ $t("rate") }}</div>
                    <div class="w-1/5">{{ $t("status") }}</div>
                    <div class="w-1/5">{{ $t("comm") }}</div>
                </div>
                <template v-if="admin.getAgents.total > 0">
                    <div
                        v-for="(user, key) in admin.getAgents.data"
                        :key="'user-' + key"
                        class="flex w-full justify-between"
                    >
                        <div class="w-1/5">
                            {{ user.code ?? "N/A" }}
                        </div>
                        <div class="w-1/5">
                            {{ user.name }}
                        </div>
                        <div class="w-1/5">
                            {{ user.cr ?? "N/A" }}
                        </div>
                        <div class="w-1/5">
                            <button
                                @click="toggleStatus(user, 'agent')"
                                :class="{
                                    'text-green-500 hover:text-green-600':
                                        user.status,
                                    'text-red-500 hover:text-red-600':
                                        !user.status,
                                }"
                            >
                                {{ user.status ? "Active" : "Deactivate" }}
                            </button>
                        </div>
                        <div class="w-1/5">
                            {{ user.commission }}
                        </div>
                    </div>
                    <div class="w-full flex gap-2">
                        <button
                            @click="prevAgentPage"
                            class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                            :class="{ invisible: searchAgent.page <= 1 }"
                        >
                            <span v-if="searchAgent.page > 1">{{
                                $t("previous")
                            }}</span>
                            <span v-else></span>
                        </button>
                        <button
                            @click="nextAgentPage"
                            class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                            :class="{
                                invisible:
                                    searchAgent.page >=
                                    admin.getAgents.last_page,
                            }"
                        >
                            <span
                                v-if="
                                    searchAgent.page < admin.getAgents.last_page
                                "
                            >
                                {{ $t("next") }}
                            </span>
                            <span v-else></span>
                        </button>
                    </div>
                </template>
                <div v-else class="w-full text-red-300 my-2">
                    {{ $t("no_record") }}
                </div>
            </div>
        </div>
        <div
            v-if="type === 'dealer' && auth.getUser.is_admin"
            class="flex-grow"
        >
            <div class="sticky top-0">
                <div class="text-2xl font-bold flex justify-between">
                    <div>{{ $t("dealer") }}</div>
                    <button
                        @click="addDealer"
                        class="rounded p-2 text-center bg-green-600 hover:bg-green-700 font-bold text-white"
                    >
                        <svg
                            v-if="!showFormDealer"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            class="w-8 h-8 mx-auto"
                        >
                            <path
                                d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM240 352c0 8.8 7.2 16 16 16s16-7.2 16-16V272h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V160c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v80z"
                            />
                        </svg>
                        <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            class="w-8 h-8 mx-auto"
                        >
                            <path
                                d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232c-13.3 0-24 10.7-24 24s10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H184z"
                            />
                        </svg>
                    </button>
                </div>
                <div class="my-5 flex">
                    <input
                        v-if="!showFormDealer"
                        type="text"
                        :placeholder="$t('search_dealer_name')"
                        class="p-2 rounded-l text-black w-full disabled:text-white border border-gray-3"
                        v-model="searchDealer.name"
                        @keyup="filterList('dealer')"
                    />
                </div>
            </div>
            <div
                v-if="showFormDealer"
                class="flex flex-col gap-5 pt-5 relative"
            >
                <input
                    type="text"
                    :placeholder="$t('dealer_code')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_dealer.code"
                />
                <input
                    type="text"
                    :placeholder="$t('dealer_commission_rate')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_dealer.cr"
                />
                <input
                    type="text"
                    :placeholder="$t('dealer_name')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_dealer.name"
                />
                <input
                    type="text"
                    :placeholder="$t('dealer_username')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_dealer.username"
                />
                <input
                    type="password"
                    :placeholder="$t('dealer_password')"
                    class="p-2 rounded text-black w-full border border-gray-3"
                    v-model="form_dealer.password"
                />
                <button
                    @click="confirmDealer"
                    class="rounded text-center w-full bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
                >
                    {{ $t("create") }}
                </button>
            </div>
            <div class="flex flex-col font-bold text-center w-full my-5">
                <div class="flex w-full justify-between">
                    <div class="w-1/5">{{ $t("code") }}</div>
                    <div class="w-1/5">{{ $t("name") }}</div>
                    <div class="w-1/5">{{ $t("rate") }}</div>
                    <div class="w-1/5">{{ $t("status") }}</div>
                    <div class="w-1/5">{{ $t("comm") }}</div>
                </div>
                <template v-if="admin.getDealers.total > 0">
                    <div
                        v-for="(user, key) in admin.getDealers.data"
                        :key="'user-' + key"
                        class="flex w-full justify-between"
                    >
                        <div class="w-1/5">
                            {{ user.code }}
                        </div>
                        <div class="w-1/5">
                            {{ user.name }}
                        </div>
                        <div class="w-1/5">
                            {{ user.cr }}
                        </div>
                        <div class="w-1/5">
                            <button
                                @click="toggleStatus(user, 'dealer')"
                                :class="{
                                    'text-green-500 hover:text-green-600':
                                        user.status,
                                    'text-red-500 hover:text-red-600':
                                        !user.status,
                                }"
                            >
                                {{ user.status ? "Active" : "Deactivate" }}
                            </button>
                        </div>
                        <div class="w-1/5">
                            {{ user.commission }}
                        </div>
                    </div>
                    <div class="w-full flex gap-2">
                        <button
                            @click="prevDealerPage"
                            class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                            :class="{ invisible: searchDealer.page <= 1 }"
                        >
                            <span v-if="searchDealer.page > 1">{{
                                $t("previous")
                            }}</span>
                            <span v-else></span>
                        </button>
                        <button
                            @click="nextDealerPage"
                            class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                            :class="{
                                invisible:
                                    searchDealer.page >=
                                    admin.getDealers.last_page,
                            }"
                        >
                            <span
                                v-if="
                                    searchDealer.page <
                                    admin.getDealers.last_page
                                "
                            >
                                {{ $t("next") }}
                            </span>
                            <span v-else></span>
                        </button>
                    </div>
                </template>
                <div v-else class="w-full text-red-300 my-2">
                    {{ $t("no_record") }}
                </div>
            </div>
        </div>
        <div
            v-if="auth.getUser.is_admin"
            class="w-full flex justify-between gap-2"
        >
            <button
                @click="type = 'agent'"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{ 'bg-green-600 hover:bg-green-700': type === 'agent' }"
            >
                {{ $t("agent") }}
            </button>
            <button
                @click="type = 'dealer'"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    'bg-green-600 hover:bg-green-700': type === 'dealer',
                }"
            >
                {{ $t("dealer") }}
            </button>
        </div>
    </div>
</template>
<script setup>
import { inject, reactive, ref } from "vue";
import { useAdminStore, useAuthStore } from "@/store";

const auth = useAuthStore();
const emitter = inject("emitter");
const admin = useAdminStore();
const form_agent = reactive({
    user_id: auth.getUser.is_dealer ? auth.getUser.id : null,
    code: null,
    cr: null,
    name: null,
    username: null,
    password: null,
});
const form_dealer = reactive({
    code: null,
    cr: null,
    name: null,
    username: null,
    password: null,
});
const type = ref(auth.getUser.is_admin ? "dealer" : "agent");

let searchAgent = reactive({
    name: null,
    page: 1,
});
let searchDealer = reactive({
    name: null,
    page: 1,
});
admin.loadAgents(searchAgent);
if (auth.getUser.is_admin) {
    admin.loadDealers(searchDealer);
}
const filterList = (type) => {
    switch (type) {
        case "dealer":
            admin.loadDealers(searchDealer);
            break;
        case "agent":
            admin.loadAgents(searchAgent);
            break;
    }
};
let showFormDealer = ref(false);
let showFormAgent = ref(false);
const addDealer = () => {
    showFormDealer.value = !showFormDealer.value;
};
const addAgent = () => {
    showFormAgent.value = !showFormAgent.value;
};

const confirmAgent = () => {
    admin.createAgent(form_agent);
    showFormAgent.value = false;
    admin.loadAgents(searchAgent);
};
const confirmDealer = () => {
    admin.createDealer(form_dealer);
    showFormDealer.value = false;
    if (auth.getUser.is_admin) {
        admin.loadDealers(searchDealer);
    }
};
const toggleStatus = (user, role = null) => {
    admin.updateUser({ id: user.id, status: !user.status }, role);
};
const nextAgentPage = () => {
    if (searchAgent.page < admin.getAgents.last_page) {
        searchAgent.page++;
        admin.loadAgents(searchAgent);
        emitter.emit("toTop");
    }
};
const prevAgentPage = () => {
    if (searchAgent.page > 1) {
        searchAgent.page--;
        admin.loadAgents(searchAgent);
        emitter.emit("toTop");
    }
};
const nextDealerPage = () => {
    if (searchDealer.page < admin.getDealers.last_page) {
        searchDealer.page++;
        admin.loadDealers(searchDealer);
        emitter.emit("toTop");
    }
};
const prevDealerPage = () => {
    if (searchDealer.page > 1) {
        searchDealer.page--;
        admin.loadDealers(searchDealer);
        emitter.emit("toTop");
    }
};
</script>
