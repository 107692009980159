import { defineStore } from "pinia";
import axios from "@/axios.js";
import { useAuthStore } from "@/store/auth.js";

export const useAdminStore = defineStore({
    id: "admin",
    state: () => {
        return {
            loading: false,
            settings: [],
            agents: [],
            dealers: [],
            odds: [],
            bets: [],
            withdraws: [],
            payments: [],
            counters: [],
            results: [],
            totalPayments: 0,
            totalAmount: 0,
            totalWithdraws: 0,
        };
    },
    getters: {
        getResults: (state) => state.results,
        getBetAmount: (state) => state.totalAmount,
        getWithdraws: (state) => state.withdraws,
        getTotalWithdraws: (state) => state.totalWithdraws,
        getPayments: (state) => state.payments,
        getTotalPayments: (state) => state.totalPayments,
        getOdds: (state) => state.odds,
        getBets: (state) => state.bets,
        getAgents: (state) => state.agents,
        getDealers: (state) => state.dealers,
        getSettings: (state) => state.settings,
        getCounters: (state) => state.counters,
        getBetRecords: (state) => state.betRecords,
        getTransactions: (state) => state.transactions,
        getHistories: (state) => state.histories,
    },
    actions: {
        loadBet(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/bet", {
                        params: {
                            page: form ? form.page : 1,
                        },
                    })
                    .then(({ data }) => {
                        this.bets = data;
                    });
            }
        },
        loadCounter(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/counter", {
                        params: {
                            page: form ? form.page : 1,
                            number: form.number,
                            sort: form ? form.sort : "last",
                        },
                    })
                    .then(({ data }) => {
                        this.counters = data.counters;
                        this.results = data.results;
                        this.totalAmount = data.total_amount;
                    });
            }
        },
        loadWithdraw(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/withdraw", {
                        params: form,
                    })
                    .then(({ data }) => {
                        this.withdraws = data.results;
                        this.totalWithdraws = data.total_amount;
                    });
            }
        },
        loadPayment(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/payment", {
                        params: form,
                    })
                    .then(({ data }) => {
                        this.payments = data.results;
                        this.totalPayments = data.total_amount;
                    });
            }
        },
        loadTransaction(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/transaction", {
                        params: form,
                    })
                    .then(({ data }) => {
                        this.transactions = data;
                    });
            }
        },
        updateWithdraw(form = null) {
            return axios.post("admin/withdraw", form);
        },
        loadAgents(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/user/agent", {
                        params: form,
                    })
                    .then(({ data, success }) => {
                        if (success) {
                            this.agents = data;
                        }
                    });
            }
        },
        loadDealers(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/user/dealer", {
                        params: form,
                    })
                    .then(({ data, success }) => {
                        if (success) {
                            this.dealers = data;
                        }
                    });
            }
        },
        createAgent(form = null) {
            return axios.post("admin/user/agent", form).then(({ success }) => {
                if (success) {
                    this.loadAgents();
                }
            });
        },
        updateUser(form = null, role = null) {
            return axios
                .post("admin/user/" + form.id, form)
                .then(({ success }) => {
                    if (success) {
                        switch (role) {
                            case "agent":
                                this.loadAgents();
                                break;
                            case "dealer":
                                this.loadDealers();
                                break;
                            default:
                                break;
                        }
                    }
                });
        },
        createDealer(form = null) {
            return axios.post("admin/user/dealer", form).then(({ success }) => {
                if (success) {
                    this.loadDealers();
                }
            });
        },
        loadResult(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/result", { params: { form } })
                    .then(({ data, success }) => {
                        if (success) {
                            this.results = data;
                        }
                    });
            }
        },
        createResult(form = null) {
            return axios.post("admin/result", form);
        },
        updateSetting({ key, value }) {
            return axios.post("admin/setting/" + key, { value });
        },
        loadSetting() {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios.get("admin/setting").then(({ data, success }) => {
                    if (success) {
                        this.settings = data;
                    }
                });
            }
        },
        loadOdds(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("admin/odds", form)
                    .then(({ data, success }) => {
                        if (success) {
                            this.odds = data;
                        }
                    });
            }
        },
        updateOdds(form = null) {
            return axios.post("admin/odds", form).then(({ data, success }) => {
                if (success) {
                    this.odds = data;
                }
            });
        },
    },
});
