<template>
    <div v-if="control.data">
        <div
            v-if="control.type !== 'loading'"
            @click.prevent="close"
            class="fixed w-screen top-0 left-0 h-screen z-30 bg-gray-800 bg-opacity-75"
        ></div>
        <div
            class="mx-auto md:w-1/2 z-40 w-full h-screen md:h-[500px] top-0 fixed inset-x-0 rounded md:top-10"
            :class="{
                'bg-white': control.type !== 'loading',
                'bg-transparent': control.type === 'loading',
            }"
        >
            <a
                href="#"
                class="absolute right-0 top-0 md:-right-5 md:-top-5 rounded-full p-2 bg-red-600"
                @click.prevent="close"
            >
                <svg
                    class="w-5"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                    />
                </svg>
            </a>
            <div class="px-6 md:px-0 my-4 text-lg flex items-center w-full">
                <div
                    v-if="control.type === 'loading'"
                    class="w-full h-full flex flex-col justify-center text-white"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-12 h-12 animate-spin mx-auto"
                        fill="currentColor"
                        viewBox="0 0 512 512"
                    >
                        <path
                            class="fa-primary"
                            d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 256A48 48 0 1 0 0 256a48 48 0 1 0 96 0zM75 142.9A48 48 0 1 0 142.9 75 48 48 0 1 0 75 142.9z"
                        />
                        <path
                            class="fa-secondary"
                            d="M369.1 75A48 48 0 1 1 437 142.9 48 48 0 1 1 369.1 75zM416 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM208 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM75 369.1A48 48 0 1 1 142.9 437 48 48 0 1 1 75 369.1zm294.2 0A48 48 0 1 1 437 437a48 48 0 1 1 -67.9-67.9z"
                        />
                    </svg>
                </div>
                <iframe
                    v-if="control.type === 'fpay'"
                    ref="iframe"
                    :src="control.data"
                    :style="iframeStyle"
                    frameborder="0"
                ></iframe>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, ref, computed } from "vue";
import { useMainStore } from "@/store/index.js";

// Reactive reference for window width
const windowWidth = ref(window.innerWidth);

// Computed property for iframe style
const iframeStyle = computed(() => {
    if (windowWidth.value <= 768) {
        return {
            height: "100vh", // Full viewport height
            width: "100%", // Full viewport width
        };
    } else {
        return {
            height: "500px", // Fixed height for non-mobile devices
            width: "100%",
        };
    }
});

// Listen to window resize events to update the windowWidth
window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
});

let control = reactive({
    type: null,
    data: null,
    amount: null,
    transaction: null,
});
const main = useMainStore();
const iframe = ref(null);
main.$subscribe(({ storeId }, { modal }) => {
    if (storeId === "main") {
        control.type = modal.type;
        control.data = modal.data;
        control.amount = modal.amount;
    }
});

const close = () => {
    main.setModal();
};
</script>
