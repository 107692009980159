import Login from "@/views/Frontend/Login.vue";
import HomeView from "@/views/Frontend/HomeView.vue";
// import Profile from "@/views/Frontend/Profile.vue";
import Info from "@/views/Frontend/Info.vue";
import History from "@/views/Frontend/History.vue";
import Wallet from "@/views/Frontend/Wallet.vue";
import Topup from "@/views/Frontend/Topup.vue";
import Withdraw from "@/views/Frontend/Withdraw.vue";
import Menu from "@/views/Frontend/Menu.vue";
import About from "@/views/Frontend/About.vue";
import Guide from "@/views/Frontend/Guide.vue";
import Bet from "@/views/Frontend/Bet.vue";
import Record from "@/views/Frontend/Record.vue";
import SuperLogin from "@/views/Frontend/SuperLogin.vue";
import Language from "@/views/Frontend/Language.vue";

export default [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            guest: true,
        },
        children: [
            {
                path: ":uid",
                name: "login_auth",
                component: Login,
            },
        ],
    },
    {
        path: "/super/login",
        name: "login_super",
        component: SuperLogin,
        meta: {
            guest: true,
        },
    },
    {
        path: "/info",
        name: "info",
        component: Info,
        meta: {
            auth: true,
        },
    },
    // {
    //   path: "/profile",
    //   name: "profile",
    //   component: Profile,
    //   meta: {
    //     auth: true,
    //   },
    // },
    {
        path: "/history",
        name: "history",
        component: History,
        meta: {
            auth: true,
        },
    },
    {
        path: "/record",
        name: "record",
        component: Record,
        meta: {
            auth: true,
        },
    },
    {
        path: "/play",
        name: "play",
        component: Bet,
        meta: {
            auth: true,
        },
    },
    {
        path: "/wallet",
        name: "wallet",
        component: Wallet,
        meta: {
            auth: true,
        },
    },
    {
        path: "/topup/:code?",
        name: "topup",
        component: Topup,
        meta: {
            auth: true,
        },
    },
    {
        path: "/withdraw",
        name: "withdraw",
        component: Withdraw,
        meta: {
            auth: true,
        },
    },
    {
        path: "/menu",
        name: "menu",
        component: Menu,
        meta: {
            auth: true,
        },
    },
    {
        path: "/language",
        name: "language",
        component: Language,
        meta: {
            auth: true,
        },
    },
    {
        path: "/about",
        name: "about",
        component: About,
        meta: {
            auth: true,
        },
    },
    {
        path: "/guide",
        name: "guide",
        component: Guide,
        meta: {
            auth: true,
        },
    },
];
