<template>
    <div class="relative flex flex-col justify-between h-full">
        <div
            v-if="tab === 'withdraw'"
            class="flex flex-col justify-between text-sm"
        >
            <div class="font-bold text-center w-full flex-grow">
                <div class="text-2xl font-bold">{{ $t("withdraw") }}</div>
                <div class="my-5 flex">
                    <div class="px-2">{{ $t("withdraw_amount") }}</div>
                    <input
                        type="number"
                        :placeholder="$t('withdraw_amount')"
                        disabled
                        readonly
                        class="p-2 rounded text-black w-full disabled:text-white disabled:bg-gray-500 border border-gray-3"
                        :value="admin.getTotalWithdraws"
                    />
                </div>
                <div
                    v-if="main.getWithdraws.total > 0"
                    class="text-center font-bold"
                >
                    {{ main.getWithdraws.current_page }} /
                    {{ main.getWithdraws.last_page }}
                </div>
                <table class="table table-auto w-full border-0">
                    <thead
                        class="top-0 sticky py-2 bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-200"
                    >
                        <tr class="font-bold">
                            <th>{{ $t("date") }}</th>
                            <th>{{ $t("status") }}</th>
                            <th>{{ $t("amount") }}</th>
                        </tr>
                    </thead>
                    <tbody class="overflow-hidden overflow-y-auto">
                        <template
                            v-if="
                                admin.getWithdraws &&
                                admin.getWithdraws.total > 0
                            "
                        >
                            <tr
                                v-for="(trans, key) in admin.getWithdraws.data"
                                :key="'bet-' + key"
                            >
                                <td>
                                    {{ main.getDate(trans.created_at) }}
                                </td>
                                <td>
                                    {{ getStatus(trans.action) }}
                                </td>
                                <td class="uppercase">
                                    {{ trans.type }}
                                </td>
                                <td>
                                    {{ trans.amount }}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <th class="text-red-300 my-2" colspan="7">
                                    {{ $t("no_record") }}
                                </th>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            v-if="tab === 'payment'"
            class="flex flex-col justify-between text-sm"
        >
            <div class="font-bold text-center w-full flex-grow">
                <div class="text-2xl font-bold">{{ $t("topup") }}</div>
                <div class="my-5 flex">
                    <div class="px-2">{{ $t("topup_amount") }}</div>
                    <input
                        type="number"
                        :placeholder="$t('topup_amount')"
                        disabled
                        readonly
                        class="p-2 rounded text-black w-full disabled:text-white disabled:bg-gray-500 border border-gray-3"
                        :value="admin.getTotalPayments"
                    />
                </div>
                <div
                    v-if="main.getPayments.total > 0"
                    class="text-center font-bold"
                >
                    {{ main.getPayments.current_page }} /
                    {{ main.getPayments.last_page }}
                </div>
                <table class="table table-auto w-full border-0 text-center">
                    <thead
                        class="top-0 sticky py-2 bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-200"
                    >
                        <tr class="font-bold">
                            <th class="text-center">{{ $t("date") }}</th>
                            <th class="text-center">{{ $t("status") }}</th>
                            <th class="text-center">{{ $t("amount") }}</th>
                        </tr>
                    </thead>
                    <tbody class="overflow-hidden overflow-y-auto">
                        <template
                            v-if="
                                admin.getPayments && admin.getPayments.total > 0
                            "
                        >
                            <tr
                                v-for="(trans, key) in admin.getPayments.data"
                                :key="'bet-' + key"
                            >
                                <td class="text-center">
                                    {{ main.getDate(trans.created_at) }}
                                </td>
                                <td class="text-center">
                                    {{ getStatus(trans.action) }}
                                </td>
                                <td class="uppercase text-center">
                                    {{ trans.type }}
                                </td>
                                <td class="text-center">
                                    {{ trans.amount }}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <th class="text-red-300 my-2" colspan="7">
                                    {{ $t("no_record") }}
                                </th>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            v-if="tab === 'transaction'"
            class="flex flex-col justify-between text-sm"
        >
            <div class="font-bold text-center w-full flex-grow">
                <div
                    v-if="main.getTransactions.total > 0"
                    class="text-center font-bold"
                >
                    {{ main.getTransactions.current_page }} /
                    {{ main.getTransactions.last_page }}
                </div>
                <table class="table table-auto w-full border-0">
                    <thead
                        class="top-0 sticky py-2 bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-200"
                    >
                        <tr class="font-bold">
                            <th>{{ $t("date") }}</th>
                            <th>{{ $t("status") }}</th>
                            <th>{{ $t("type") }}</th>
                            <th>{{ $t("in") }}</th>
                            <th>{{ $t("out") }}</th>
                        </tr>
                    </thead>
                    <tbody class="overflow-hidden overflow-y-auto">
                        <template
                            v-if="
                                admin.getTransactions &&
                                admin.getTransactions.total > 0
                            "
                        >
                            <tr
                                v-for="(trans, key) in admin.getTransactions
                                    .data"
                                :key="'bet-' + key"
                                :class="{
                                    'text-green-500': trans.type === 'CASH',
                                }"
                            >
                                <td>
                                    {{ main.getDate(trans.created_at) }}
                                </td>
                                <td>
                                    {{ getAction(trans.action) }}
                                </td>
                                <td class="uppercase">
                                    {{ getType(trans.type) }}
                                </td>
                                <td>
                                    {{ trans.in }}
                                </td>
                                <td>
                                    {{ trans.out }}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <th class="text-red-300 my-2" colspan="7">
                                    {{ $t("no_record") }}
                                </th>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            v-if="getData && getData.total > 0"
            class="w-full flex gap-2 py-2 my-5"
        >
            <button
                :class="{ invisible: getForm.page <= 1 }"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                @click="prevPage"
            >
                <span v-if="getForm.page > 1">{{ $t("previous") }}</span>
                <span v-else></span>
            </button>
            <button
                :class="{
                    invisible: getForm.page >= getData.last_page,
                }"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                @click="nextPage"
            >
                <span v-if="getForm.page < getData.last_page">
                    {{ $t("next") }}
                </span>
                <span v-else></span>
            </button>
        </div>
        <div>
            <div class="w-full mb-2">
                <button
                    @click="todayFilter"
                    class="rounded w-full py-2 bg-orange-500 text-white"
                    :class="{
                        'bg-green-600 hover:bg-green-700': todayStatus,
                    }"
                >
                    {{ $t("today") }}
                </button>
            </div>
            <div class="w-full flex justify-between gap-2">
                <button
                    @click="tab = 'payment'"
                    class="rounded w-1/2 py-2 text-white"
                    :class="{
                        'bg-green-600 hover:bg-green-700': tab === 'payment',
                        'bg-orange-500 hover:bg-orange-600': tab !== 'payment',
                    }"
                >
                    {{ $t("in") }}
                </button>
                <button
                    @click="tab = 'withdraw'"
                    class="rounded w-1/2 py-2 text-white"
                    :class="{
                        'bg-green-600 hover:bg-green-700': tab === 'withdraw',
                        'bg-orange-500 hover:bg-orange-600': tab !== 'withdraw',
                    }"
                >
                    {{ $t("out") }}
                </button>
                <button
                    @click="tab = 'transaction'"
                    class="rounded w-1/2 py-2 text-white"
                    :class="{
                        'bg-green-600 hover:bg-green-700':
                            tab === 'transaction',
                        'bg-orange-500 hover:bg-orange-600':
                            tab !== 'transaction',
                    }"
                >
                    {{ $t("transaction") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAdminStore, useMainStore } from "@/store/index.js";
import { inject, reactive, ref } from "vue";
import moment from "moment";

const emitter = inject("emitter");
const admin = useAdminStore();
const main = useMainStore();
const tab = ref("payment");
let formPayment = reactive({
    page: 1,
    created_at: null,
});
let formWithdraw = reactive({
    page: 1,
    created_at: null,
});
let formTransaction = reactive({
    page: 1,
    created_at: null,
});
const todayStatus = ref(false);
admin.loadPayment(formPayment);
admin.loadTransaction(formTransaction);
admin.loadWithdraw(formWithdraw);
const nextPage = () => {
    if (formTransaction.page < admin.getTransactions.last_page) {
        formTransaction.page++;
        admin.loadTransaction(formTransaction);
        emitter.emit("toTop");
    }
};
const prevPage = () => {
    const form = getForm();
    if (form.page > 1) {
        form.page--;
    }
    switch (tab.value) {
        case "payment":
            return admin.loadPayment(form);
        case "withdraw":
            return admin.loadWithdraw(form);
        case "transaction":
            return admin.loadTransaction(form);
    }
    emitter.emit("toTop");
};
const todayFilter = () => {
    todayStatus.value = !todayStatus.value;
    const form = getForm();
    form.created_at = todayStatus.value ? moment().format("DD-MM-YYYY") : null;
    switch (tab.value) {
        case "payment":
            return admin.loadPayment(form);
        case "withdraw":
            return admin.loadWithdraw(form);
        case "transaction":
            return admin.loadTransaction(form);
    }
};
const getData = () => {
    switch (tab.value) {
        case "payment":
            return admin.getPayments;
        case "withdraw":
            return admin.getWithdraws;
        case "transaction":
            return admin.getTransactions;
    }
};
const getForm = () => {
    switch (tab.value) {
        case "payment":
            return formPayment;
        case "withdraw":
            return formWithdraw;
        case "transaction":
            return formTransaction;
    }
};

const getAction = (action) => {
    switch (action) {
        case "win":
            return "Win";
        case "bet":
            return "Bet";
        case "commission":
            return "Comm";
        case "topup":
            return "IN";
        case "withdraw":
            return "OUT";
        default:
            return "Other";
    }
};
const getStatus = (status) => {
    switch (status) {
        case 1:
            return "Pending";
        case 2:
            return "Success";
        default:
            return "Failed";
    }
};

const getType = (bet) => {
    if (bet.action === "win") {
        return bet.transferable?.match;
    }
    return bet.type;
};
</script>
