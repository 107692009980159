<template>
    <div>
        <div class="flex flex-col gap-5 w-full text-white">
            <a
                href="#"
                @click.prevent="changeLanguage('en')"
                class="block py-2 text-xl rounded"
                :class="{
                    'bg-orange-500 hover:bg-orange-600': current !== 'en',
                    'bg-green-600 hover:bg-green-700': current === 'en',
                }"
            >
                English
            </a>
            <a
                href="#"
                @click.prevent="changeLanguage('th')"
                class="block py-2 text-xl rounded"
                :class="{
                    'bg-orange-500 hover:bg-orange-600': current !== 'th',
                    'bg-green-600 hover:bg-green-700': current === 'th',
                }"
            >
                Thai
            </a>
            <a
                href="#"
                @click.prevent="changeLanguage('zh')"
                class="block py-2 text-xl rounded"
                :class="{
                    'bg-orange-500 hover:bg-orange-600': current !== 'zh',
                    'bg-green-600 hover:bg-green-700': current === 'zh',
                }"
            >
                中文
            </a>
            <a
                href="#"
                @click.prevent="changeLanguage('vn')"
                class="block py-2 text-xl rounded"
                :class="{
                    'bg-orange-500 hover:bg-orange-600': current !== 'vn',
                    'bg-green-600 hover:bg-green-700': current === 'vn',
                }"
            >
                Viêt Nam
            </a>
        </div>
    </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { inject } from "vue";

const emitter = inject("emitter");
const i18n = useI18n();
const current = i18n.locale;

const changeLanguage = (lang) => {
    emitter.emit("changeLanguage", lang);
};
</script>
