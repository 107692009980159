<template>
    <div class="w-full h-screen flex flex-row items-center">
        <div class="mx-auto">
            <div>
                <img
                    src="@/assets/logo.png"
                    class="w-28 h-28 mx-auto"
                    :alt="$t('super_5')"
                />
            </div>
            <div
                class="py-5 text-center text-2xl font-bold font-sans uppercase"
            >
                {{ $t("login") }}
            </div>
            <a :href="loginUrl" class="group">
                <img
                    src="@/assets/btn/line/btn_login_base.png"
                    class="group-hover:hidden group-focus:hidden"
                    :alt="$t('line_login')"
                />
                <img
                    src="@/assets/btn/line/btn_login_hover.png"
                    class="hidden group-focus:hidden group-hover:block"
                    :alt="$t('line_login')"
                />
                <img
                    src="@/assets/btn/line/btn_login_press.png"
                    class="hidden group-focus:block"
                    :alt="$t('line_login')"
                />
            </a>
        </div>
    </div>
</template>
<script setup>
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/index.js";
const route = useRoute();
const refer = route.query?.refer;
const loginUrl = process.env.VUE_APP_LINE_LOGIN + "?refer=" + refer;
if (route.params.uid) {
    const token = route.params.uid;
    const auth = useAuthStore();
    auth.assignUser({ token });
}
</script>
