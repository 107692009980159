<template>
    <div>
        <div class="text-2xl font-bold">{{ $t("withdraw2") }}</div>
        <div class="flex flex-col gap-5 mt-5">
            <div class="">
                <div class="flex flex-col w-full mx-auto gap-2">
                    <select
                        class="p-2 rounded text-black w-full"
                        v-model="form.bank_id"
                    >
                        <option value="">{{ $t("select_bank") }}</option>
                        <option
                            v-for="(bank, key) in main.getWithdrawBanks"
                            :key="'dealer-' + key"
                            :value="bank.id"
                        >
                            {{ bank.bank_name }}
                        </option>
                    </select>
                    <input
                        type="text"
                        :placeholder="$t('account_name')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                        v-model="form.account_name"
                    />
                    <input
                        type="text"
                        :placeholder="$t('account_no')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                        v-model="form.account_no"
                    />
                    <input
                        type="number"
                        @keyup="checkWithdraw"
                        :placeholder="$t('amount_to_withdraw')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                        :disabled="withdraw < 1"
                        v-model="form.amount"
                    />
                    <button
                        @click="submitWithdraw"
                        class="rounded text-center w-full font-bold py-2 bg-green-600 hover:bg-green-700 disabled:bg-gray-500 disabled:hover:bg-gray-600 text-white"
                        :class="{
                            'bg-gray-500 hover:bg-gray-600': withdraw < 100,
                            'bg-green-600 hover:bg-green-700': withdraw > 100,
                        }"
                    >
                        {{ $t("withdraw2") }}
                    </button>
                </div>
                <div class="text-red-500">
                    {{
                        $t("notice_withdraw_will_be_proceed_directly_and_recei")
                    }}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAuthStore, useMainStore } from "@/store/index.js";
import { reactive } from "vue";

const auth = useAuthStore();
const withdraw = auth.getUser.cash;
const main = useMainStore();
main.loadBanks();

const form = reactive({
    bank_id: "",
    bank_branch: null,
    amount: 0,
    account_name: null,
    account_no: null,
});
const submitWithdraw = () => {
    if (auth.getUser.cash < form.amount) {
        main.addMessage({
            message: "You don't have enough WIN for withdraw.",
        });
        return;
    }
    main.createWithdraw(form);
};
const checkWithdraw = (event) => {
    if (event.target.value >= auth.getUser.cash) {
        event.target.value = auth.getUser.cash;
    }
};
</script>
