<template>
    <div class="relative h-full">
        <div class="flex flex-col h-full">
            <div
                v-if="main.getHistories && main.getHistories.total > 0"
                class="overflow-hidden overflow-y-auto flex-grow"
            >
                <div
                    v-for="(history, key) in main.getHistories.data"
                    :key="'history-' + key"
                    class="my-5"
                >
                    <!--suppress AllyHtmlVueInspection -->
                    <div class="font-bold">
                        {{ main.getDate(history.created_at) }}
                    </div>
                    <div class="font-bold text-2xl">
                        {{ history.result }}
                    </div>
                    <div class="flex flex-col gap-2">
                        <div
                            v-for="(result, key) in history.data"
                            :key="'data_' + key"
                            class="flex flex-row w-full gap-2"
                        >
                            <div
                                class="rounded border-2 border-red-300 w-1/2 text-right px-2"
                            >
                                {{ result.number }}
                            </div>
                            <div
                                class="rounded border-2 border-red-300 w-1/2 text-left px-2"
                            >
                                {{
                                    $t("hit_" + result.number.length + "_digit")
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="w-full text-red-300 my-2">
                {{ $t("no_record") }}
            </div>
            <div
                v-if="main.getHistories && main.getHistories.total > 0"
                class="w-full flex gap-2 py-2"
            >
                <button
                    @click="prevPage"
                    class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                    :class="{
                        invisible: form.page <= 1,
                    }"
                >
                    <span v-if="form.page > 1">{{ $t("previous") }}</span>
                    <span v-else></span>
                </button>
                <button
                    @click="nextPage"
                    class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                    :class="{
                        invisible: form.page >= main.getHistories.last_page,
                    }"
                >
                    <span v-if="form.page < main.getHistories.last_page">{{
                        $t("next")
                    }}</span>
                    <span v-else></span>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";
import { inject, onBeforeMount, reactive } from "vue";

const emitter = inject("emitter");
const main = useMainStore();
let form = reactive({
    page: 1,
});

onBeforeMount(() => {
    main.loadHistories(form);
});
const nextPage = () => {
    if (form.page < main.getHistories.last_page) {
        form.page++;
        main.loadHistories(form);
        emitter.emit("toTop");
    }
};
const prevPage = () => {
    if (form.page > 1) {
        form.page--;
        main.loadHistories(form);
        emitter.emit("toTop");
    }
};
</script>
