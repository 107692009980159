import { createApp } from "vue";
import App from "./App.vue";
import "./app.css";
import "./registerServiceWorker";
import router from "./router";
import mixins from "./mixins";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { createPinia } from "pinia";
import Backend from "@/layouts/Backend.vue";
import Default from "@/layouts/Default.vue";
import Emitter from "tiny-emitter";
import { createI18n } from "vue-i18n";
import "./registerServiceWorker.js";
import messages from "@/i18n";

var emitter = new Emitter();
const i18n = createI18n({
    legacy: false,
    locale: "en",
    fallbackLocale: "en",
    messages,
});
const app = createApp(App);
app.use(createPinia());
app.mixin(mixins);
app.use(i18n);
app.use(router);
app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPCTA_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
});
app.provide("emitter", emitter);
app.component("default_layout", Default);
app.component("admin_layout", Backend);
app.component("empty_layout", null);
app.mount("#app");
