<template>
    <div>
        <div class="flex flex-wrap justify-around mt-5">
            <button
                @click="page = 'topup'"
                class="text-lg rounded py-2 px-3 text-white"
                :class="{
                    'bg-orange-500 hover:bg-orange-600': page !== 'topup',
                    'bg-green-600 hover:bg-green-700': page === 'topup',
                }"
            >
                {{ $t("topup") }}
            </button>
            <button
                @click="page = 'withdraw'"
                class="text-lg rounded py-2 px-3 text-white"
                :class="{
                    'bg-orange-500 hover:bg-orange-600': page !== 'withdraw',
                    'bg-green-600 hover:bg-green-700': page === 'withdraw',
                }"
            >
                {{ $t("withdraw") }}
            </button>
        </div>
        <div class="flex flex-col gap-5 mt-5">
            <template v-if="page === 'topup'">
                <div class="flex flex-col gap-2">
                    <input
                        type="number"
                        :placeholder="$t('credit2')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-300"
                        v-model="topupForm.price"
                    />
                    <select
                        class="p-2 rounded text-black w-full"
                        v-model="topupForm.type"
                        disabled="disabled"
                        aria-readonly="true"
                        readonly="readonly"
                    >
                        <option value="">{{ $t("select_method") }}</option>
                        <option
                            v-for="(payment, key) in paymentMethods"
                            :key="'method-' + key"
                            :value="payment.code"
                        >
                            {{ payment.name }}
                        </option>
                    </select>
                    <button
                        @click="payNow"
                        class="rounded text-center mx-auto bg-green-600 hover:bg-green-700 font-bold px-3 py-2 text-white"
                    >
                        {{ $t("topup_now") }}
                    </button>
                </div>
            </template>
            <template v-if="page === 'withdraw'">
                <div class="flex flex-col gap-2">
                    <select
                        class="p-2 rounded text-black w-full"
                        v-model="withdrawForm.bank_id"
                    >
                        <option value="">{{ $t("select_bank") }}</option>
                        <option
                            v-for="(bank, key) in main.getWithdrawBanks"
                            :key="'dealer-' + key"
                            :value="bank.id"
                        >
                            {{ bank.bank_name }}
                        </option>
                    </select>
                    <input
                        type="text"
                        :placeholder="$t('account_name')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                        v-model="withdrawForm.account_name"
                    />
                    <input
                        type="text"
                        :placeholder="$t('account_no')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                        v-model="withdrawForm.account_no"

                    />
                    <input
                        type="number"
                        @keyup="checkWithdraw"
                        :placeholder="$t('amount_to_withdraw')"
                        class="p-2 rounded text-black flex-grow disabled:bg-gray-500 disabled:hover:bg-gray-600 border border-gray-3"
                        :disabled="withdraw < 1"
                        v-model="withdrawForm.amount"
                    />
                    <button
                        @click="submitWithdraw"
                        class="rounded text-center mx-auto w-1/3 font-bold py-2 text-white"
                        :class="{
                            'bg-gray-500 hover:bg-gray-600':
                                auth.getUser.cash < 100,
                            'bg-green-600 hover:bg-green-700':
                                auth.getUser.cash > 100,
                        }"
                    >
                        {{ $t("withdraw2") }}
                    </button>
                </div>
                <div class="text-red-500">
                    {{
                        $t("notice_withdraw_will_be_proceed_directly_and_recei")
                    }}
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { useAuthStore, useMainStore } from "@/store/index.js";
import { reactive, ref } from "vue";

const auth = useAuthStore();
let topupForm = reactive({ price: 100, type: "prompt" });
const withdraw = auth.getUser.cash;
const main = useMainStore();
main.loadBanks();
let page = ref("topup");
const withdrawForm = reactive({
    bank_id: "",
    bank_branch: null,
    amount: withdraw,
    account_name: null,
    account_no: null,
});
let paymentMethods = [
    {
        name: "PromptPay",
        code: "prompt",
    },
];

auth.$subscribe((mutation, state) => {
    withdrawForm.amount = state.user.cash;
});

const loadingPayment = ref(false);
const submitWithdraw = () => {
    if (auth.getUser.cash < withdrawForm.amount) {
        main.addMessage({
            message: "You don't have enough WIN for withdraw.",
        });
        return;
    }
    main.createWithdraw(withdrawForm);
};
const checkWithdraw = (event) => {
    if (event.target.value >= auth.getUser.cash) {
        event.target.value = auth.getUser.cash;
    }
};
const payNow = () => {
    if (!topupForm.price) {
        main.addMessage({
            message: "Please enter amount that you want to topup",
        });
        return;
    }
    if (loadingPayment.value) {
        main.addMessage({
            message: "Please wait, your payment request is on progress.",
        });
        return;
    }
    loadingPayment.value = true;
    main.setModal("loading", true);
    main.createPayment(topupForm)
        .then((res) => {
            if (res.data.payment_url) {
                main.setModal("fpay", res.data.payment_url);
            }
            loadingPayment.value = false;
        })
        .catch(() => {
            main.setModal("loading", false);
            loadingPayment.value = false;
        });
};
</script>
