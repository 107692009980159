<template>
    <div
        class="w-full md:w-1/2 mx-auto md:border-2 md:border-orange-500 relative pb-20 flex flex-col h-screen"
    >
        <div v-if="deferredPrompt" class="py-2 flex bg-amber-500">
            <div class="flex-grow pl-5 text-2xl">
                {{ $t("install_super5_now") }}
            </div>
            <div class="flex gap-2 w-1/3">
                <button
                    @click="install"
                    class="rounded p-2 bg-green-600 text-xs font-semibold shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 text-white"
                >
                    {{ $t("install") }}
                </button>
                <button
                    @click="dismiss"
                    class="rounded p-2 bg-gray-600 text-xs font-semibold shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 text-white"
                >
                    {{ $t("close") }}
                </button>
            </div>
        </div>
        <div
            class="flex w-full items-center p-5 h-20 bg-orange-600 border-b-2 border-orange-600"
        >
            <button
                class="rounded-full text-white w-12 h-12 p-2 bg-orange-400 text-xs font-semibold shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 text-white"
            >
                <img
                    v-if="auth.getUser.avatar"
                    :src="auth.getUser.avatar"
                    :alt="auth.getUser.name"
                />
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="currentColor"
                    class="w-8 h-8 text-gray-100"
                >
                    <path
                        d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                    />
                </svg>
            </button>
            <div class="flex-grow mx-5 text-white">
                <div class="font-bold text-xl">{{ auth.getUser.name }}</div>
                <div class="flex flex-row gap-4">
                    <div>{{ $t("cash", { cash: auth.getUser.cash }) }}</div>
                    <div>{{ $t("comm_rate", { cr: auth.getUser.cr }) }}</div>
                </div>
            </div>
        </div>
        <div
            ref="screen"
            class="px-5 pt-2 w-full overflow-hidden flex-grow overflow-y-auto mx-auto text-center relative"
        >
            <slot />
        </div>
        <div
            class="absolute bg-orange-600 w-full bottom-0 left-0 flex flex-row justify-around border-t-2 border-orange-500 py-3"
        >
            <router-link
                :to="{ name: 'admin_bet' }"
                class="rounded-full text-white w-12 h-12 p-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                    'bg-green-600 hover:bg-green-700':
                        route.name === 'admin_bet',
                    'bg-purple-600 hover:bg-purple-700':
                        route.name !== 'admin_bet',
                }"
            >
                <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm47-320.7l105.1 15.3-76.1 74.2 18 104.7L256 336l-94 49.4 18-104.7-76.1-74.2L209 191.3 256 96l47 95.3z"
                    />
                </svg>
            </router-link>
            <router-link
                v-if="auth.getUser.is_admin"
                :to="{ name: 'admin_result' }"
                class="rounded-full text-white w-12 h-12 p-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                    'bg-green-600 hover:bg-green-700':
                        route.name === 'admin_result',
                    'bg-purple-600 hover:bg-purple-700':
                        route.name !== 'admin_result',
                }"
            >
                <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM128 224c8.8 0 16 7.2 16 16V368c0 8.8-7.2 16-16 16s-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80-80c0-8.8 7.2-16 16-16s16 7.2 16 16V368c0 8.8-7.2 16-16 16s-16-7.2-16-16V144zM320 288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16z"
                    />
                </svg>
            </router-link>
            <router-link
                v-if="auth.getUser.is_admin || auth.getUser.is_dealer"
                :to="{ name: 'admin_agent' }"
                class="rounded-full text-white w-12 h-12 p-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                    'bg-green-600 hover:bg-green-700':
                        route.name === 'admin_agent',
                    'bg-purple-600 hover:bg-purple-700':
                        route.name !== 'admin_agent',
                }"
            >
                <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M190.5 328.2l18.6 31L175.8 483.1 136.3 321.9C59.1 333.9 0 400.7 0 481.3c0 17 13.8 30.7 30.7 30.7H182.9h82.3H417.3c17 0 30.7-13.8 30.7-30.7c0-80.6-59.1-147.4-136.3-159.4L272.2 483.1 238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2H224 204.3c-12.4 0-20.1 13.6-13.7 24.2z"
                    />
                    <path
                        d="M352 128A128 128 0 1 1 96 128a128 128 0 1 1 256 0z"
                    />
                </svg>
            </router-link>
            <router-link
                v-if="auth.getUser.is_agent || auth.getUser.is_dealer"
                :to="{ name: 'admin_wallet' }"
                class="rounded-full text-white w-12 h-12 p-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                    'bg-green-600 hover:bg-green-700':
                        route.name === 'admin_wallet',
                    'bg-purple-600 hover:bg-purple-700':
                        route.name !== 'admin_wallet',
                }"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-8 h-8"
                >
                    <path
                        d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64H80c-8.8 0-16-7.2-16-16s7.2-16 16-16H448c17.7 0 32-14.3 32-32s-14.3-32-32-32H64zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                    />
                </svg>
            </router-link>
            <router-link
                v-if="auth.getUser.is_admin"
                :to="{ name: 'admin_transaction' }"
                class="rounded-full text-white w-12 h-12 p-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                    'bg-green-600 hover:bg-green-700':
                        route.name === 'admin_transaction',
                    'bg-purple-600 hover:bg-purple-700':
                        route.name !== 'admin_transaction',
                }"
            >
                <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    viewBox="0 0 384 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M64 496c-26.5 0-48-21.5-48-48V64c0-26.5 21.5-48 48-48H204.1c1.3 0 2.6 .1 3.9 .2V136c0 22.1 17.9 40 40 40H367.8c.2 1.3 .2 2.6 .2 3.9V448c0 26.5-21.5 48-48 48H64zM358.6 157.3c.9 .9 1.7 1.8 2.4 2.7H248c-13.3 0-24-10.7-24-24V22.9c1 .8 1.9 1.6 2.7 2.4L358.6 157.3zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V179.9c0-12.7-5.1-24.9-14.1-33.9L238.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zm0 72c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8s-3.6-8-8-8H72c-4.4 0-8 3.6-8 8zm0 64c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8s-3.6-8-8-8H72c-4.4 0-8 3.6-8 8zM192 242.7c-4.4 0-8 3.6-8 8v13.7c-8.3 .6-16 2.3-22.7 5c-12.1 4.9-22.1 13.6-24.5 26.2c-1.5 7.6-.6 14.4 2.6 20.4c3.1 5.8 8 10.1 13.4 13.3c10 6 23.5 9.6 35.7 12.9l1.7 .4c13.4 3.6 25.1 6.9 33 12c3.8 2.4 6.2 5 7.5 7.7c1.3 2.6 1.9 6 .9 11c-1.3 6.6-6 11.8-14.8 15.2c-9 3.4-21.4 4.5-35.8 2.5c-7.2-1-19.4-4.7-28.9-7.6c-2.1-.6-4-1.2-5.7-1.7c-4.2-1.3-8.7 1.2-9.9 5.4s1.2 8.7 5.4 9.9c1.4 .4 3.1 .9 5 1.5c9.4 2.9 23.3 7.1 31.9 8.3l0 0c1.8 .2 3.6 .5 5.3 .6v13.9c0 4.4 3.6 8 8 8s8-3.6 8-8l0-13.5c8.2-.4 15.8-1.9 22.4-4.4c12.4-4.7 22.2-13.6 24.8-27.1c1.5-7.8 .8-14.9-2.3-21.1c-3-6.1-7.8-10.6-13.2-14.1c-10.4-6.7-24.8-10.6-37.5-14l-.3-.1c-13.4-3.6-25-6.7-33-11.5c-3.9-2.3-6.2-4.7-7.5-7.1c-1.2-2.3-1.9-5.3-1-9.8c1-5.4 5.6-10.8 14.8-14.5c9.1-3.7 21.7-5.1 35.9-3.2c4.6 .6 18.6 3.1 23 4.1c4.3 1 8.6-1.6 9.6-5.9s-1.6-8.6-5.9-9.6c-5.1-1.2-19.7-3.7-24.5-4.4c-1.8-.2-3.6-.5-5.3-.6V250.7c0-4.4-3.6-8-8-8z"
                    />
                </svg>
            </router-link>
            <button
                @click="auth.logout()"
                class="rounded-full text-white w-12 h-12 p-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-white"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-8 h-8"
                >
                    <path
                        d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                    />
                </svg>
            </button>
        </div>
        <button
            v-if="!auth.getUser.is_dealer && !auth.getUser.is_admin"
            @click="shareLink('line')"
            class="absolute bottom-24 right-5 bg-opacity-75 rounded-full bg-pink-500 hover:bg-pink-600 p-2 text-center text-white"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.6em"
                fill="currentColor"
                viewBox="0 0 448 512"
                class="pr-1"
            >
                <path
                    d="M448 112c0 44.2-35.8 80-80 80c-22.9 0-43.6-9.6-58.1-25l-151 75.5c.8 4.4 1.1 8.9 1.1 13.5s-.4 9.1-1.1 13.5l151 75.5c14.6-15.4 35.2-25 58.1-25c44.2 0 80 35.8 80 80s-35.8 80-80 80s-80-35.8-80-80c0-9.7 1.7-19 4.9-27.7L147.2 299.5c-14.3 22-39 36.5-67.2 36.5c-44.2 0-80-35.8-80-80s35.8-80 80-80c28.2 0 52.9 14.5 67.2 36.5l145.7-72.9c-3.2-8.6-4.9-17.9-4.9-27.7c0-44.2 35.8-80 80-80s80 35.8 80 80zM80 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM416 112a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM368 448a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                />
            </svg>
        </button>
    </div>
</template>
<script setup>
import { RouterLink, useRoute } from "vue-router";
import { inject, ref } from "vue";
import { useAuthStore, useMainStore } from "@/store/index.js";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const emitter = inject("emitter");
const screen = ref();
const route = useRoute();
const auth = useAuthStore();
const main = useMainStore();

emitter.on("toTop", () => {
    screen.value.scrollTop = 0;
});

let deferredPrompt = ref(null);
window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    if (cookies.get("add-to-home-screen") === undefined) {
        deferredPrompt.value = e;
    }
});
window.addEventListener("appinstalled", () => {
    deferredPrompt.value = null;
});
const dismiss = async () => {
    deferredPrompt.value = null;
};
const install = async () => {
    deferredPrompt.value.prompt();
};
const shareLink = (type = "text") => {
    const textArea = document.createElement("textarea");
    textArea.value =
        process.env.VUE_APP_LINE_LOGIN + "?refer=" + auth.getUser.refer;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    switch (type) {
        case "line":
            window.open(
                "https://social-plugins.line.me/lineit/share?url=" +
                    process.env.VUE_APP_LINE_LOGIN +
                    "?refer=" +
                    auth.getUser.refer
            );
            return;
        case "whatsapp":
            window.open(
                "whatsapp://send?text=" +
                    process.env.VUE_APP_LINE_LOGIN +
                    "?refer=" +
                    auth.getUser.refer
            );
            return;
        case "wechat":
            window.open("weixin://dl/chat");
            return;
        default:
    }
    let message = "Copied";
    main.addMessage({ message: message, success: true });
};
</script>
<style scoped></style>
