export default {
    about: "About",
    super5_lottery_pvt_ltd_super5_lottery_formerly_kno:
        "Super Lottery Pvt Ltd (Super Lottery), formerly known as Super Lottery Pvt Ltd was first incorporated by the Government of Holland.\n" +
        "Today, Super Lottery is the largest number forecast operator in the country both in terms of number of outlets and product offerings. It has approximately 680 sales outlets offering a total of 8 games i.e. Super 4, Super 4 Jackpot, Super 4 Zodiac, Super 5, Super 6, Super 6/45, Super 6/55 and Super 6/65. It is also the sole licensed national lotto operator. Super Lottery has a steadfast commitment as a responsible corporate citizen. Since its inception, substantial contributions have been made in various corporate social responsibility areas such as charity, community sports, education and cultural promotion. In line with our philosophy of promoting a caring and sustainable society, we constantly lend our hands to the underprivileged. We continue to support and nurture sporting excellence in the country through huge annual contributions to the National Sports Council and active participation in numerous community sports initiatives.",
    origin_of_super5_lottery: "Origin Of Super5 Lottery",
    about2: "ABOUT",
    number_5_digit: "The number accept only 1 - 5 digits",
    next: "Next",
    previous: "Previous",
    for_old_record: "for old record.",
    check_here: "Check your betting history here.",
    no_new_bet_record: "No bets yet.",
    key: "{format}",
    amount: "Amount",
    number: "Number",
    date: "Date",
    confirm: "Confirm",
    amount_to_play: "Amount to Play",
    number_to_play: "Number to Play",
    bet: "Bet",
    comm_rate: "Comm. Rate : {cr}",
    cash: "Cash : {cash}",
    close: "CLOSE",
    install: "INSTALL",
    install_super5_now: "Install Super5 Now",
    bet_amount: "Bet Amount",
    bet_list: "Bet List",
    win: "Win : {cash}",
    credit: "Credit : {credit}",
    "4_click_confirm_to_confirm_your_bet":
        "4. Click [Confirm] to confirm your bet.",
    "3_insert_the_credit_you_want_to_bet":
        "3. Insert the credit you want to bet.",
    "2_insert_the_number_you_want_to_bet":
        "2. Insert the number you want to bet.",
    "1_click_middle_of_the_button_below":
        "1. Click middle of the button below.",
    how_to_play: "How to Play",
    result_will_draw_on_utc_8_daily:
        "Result will announce on 18:00 (UTC + 8) daily",
    you_may_withdraw_after_results_released:
        "You may withdraw after results released.",
    minimum_bet: "Just bet with a minimum of ${expr} Baht for a chance to win",
    "1_win": "$1 win {odds}",
    hit_1_digit: "Hit Last 1 digit",
    hit_2_digit: "Hit Last 2 digit",
    hit_3_digit: "Hit Last 3 digit",
    hit_4_digit: "Hit Last 4 digit",
    hit_5_digit: "Hit All 5 digit",
    dealer: "Dealer",
    agent: "Agent",
    comm: "Comm",
    status: "Status",
    rate: "Rate",
    name: "Name",
    code: "Code",
    search_dealer_name: "Search Dealer Name",
    create: "Create",
    dealer_password: "Dealer Password",
    dealer_name: "Dealer Name",
    dealer_username: "Dealer Username",
    dealer_commission_rate: "Dealer Commission Rate",
    dealer_code: "Dealer Code",
    search_agent_name: "Search Agent Name",
    agent_password: "Agent Password",
    agent_username: "Agent Username",
    agent_name: "Agent Name",
    agent_commission_rate: "Agent Commission Rate",
    agent_code: "Agent Code",
    no: "No",
    line_login: "Line Login",
    login: "Login",
    super_5: "Super 5",
    logout: "Logout",
    how_to_play2: "How to play",
    language: "Language",
    past_result: "Past Result",
    close2: "Close",
    failed: "Failed! ({count})",
    successfully: "Successfully! ({count})",
    pagination: "Pagination",
    results: "{expr} results",
    of: "{expr} of {expr}",
    to: "{expr} to {expr}",
    showing: "Showing {expr}",
    update: "UPDATE",
    back: "BACK",
    confirm_password: "Confirm Password",
    password: "Password",
    email: "Email",
    username: "Username",
    profile: "Profile",
    transaction: "Transaction",
    bet_record: "Bet Record",
    no_record: "NO RECORD.",
    set: "SET",
    bet2: "BET",
    percent: "PERCENT",
    out: "OUT",
    number2: "NUMBER",
    ok: "OK",
    result_number: "Result Number",
    select_result: "Select Result",
    save: "SAVE",
    payout_percent: "Payout Percent",
    payout: "Payout %",
    result_setting: "Result Setting",
    sign_in: "Sign in",
    topup_now: "TOPUP NOW",
    credit2: "Credit",
    "1000_1000_credit": "$1000 = 1000 Credit",
    "100_100_credit": "$100 = 100 Credit",
    "10_10_credit": "$10 = 10 Credit",
    notice: "Notice",
    topup: "Top Up",
    in: "IN",
    today: "TODAY",
    detail: "Detail",
    withdraw: "Withdraw",
    withdraw_amount: "Withdraw Amount",
    topup2: "Top-Up",
    topup_amount: "Top-Up Amount",
    out2: "Out",
    in2: "In",
    type: "Type",
    notice_withdraw_will_be_proceed_directly_and_recei:
        "Notice: Withdraw will be proceed directly and received within 2 hours base on bank.",
    withdraw2: "WITHDRAW",
    amount_to_withdraw: "Amount to withdraw",
    account_no: "Account No.",
    account_name: "Account Name",
    select_bank: "Select Bank",
    select_method: "Select Method",
    topup_withdraw: "TOPUP / WITHDRAW",
    account_number: "Account Number",
    owner_name: "Owner Name",
    high_win_rate: "High odds, 1 baht has chance to win {expr} baht",
    top_up_your_credit: "Top up your credit",
    here: "Here",
    remark: "Remark",
    action: "From",
    minimum_10: "Amount must be more than 10",
    bet_notice: "Cannot Be Cancel After Click Confirm",
    sort_bet_first: "Sort Ascending",
    sort_bet_last: "Sort Descending",
    bet_users: "Total Bet User",
    today_result: "Today Result",
};
