export default {
    about: "เกี่ยวกับ",
    super5_lottery_pvt_ltd_super5_lottery_formerly_kno:
        "Super Lottery Pvt Ltd (Super Lottery), formerly known as Super Lottery Pvt Ltd was first incorporated by the Government of Holland.\n" +
        "Today, Super Lottery is the largest number forecast operator in the country both in terms of number of outlets and product offerings. It has approximately 680 sales outlets offering a total of 8 games i.e. Super 4, Super 4 Jackpot, Super 4 Zodiac, Super 5, Super 6, Super 6/45, Super 6/55 and Super 6/65. It is also the sole licensed national lotto operator. Super Lottery has a steadfast commitment as a responsible corporate citizen. Since its inception, substantial contributions have been made in various corporate social responsibility areas such as charity, community sports, education and cultural promotion. In line with our philosophy of promoting a caring and sustainable society, we constantly lend our hands to the underprivileged. We continue to support and nurture sporting excellence in the country through huge annual contributions to the National Sports Council and active participation in numerous community sports initiatives.",
    origin_of_super5_lottery: "Origin Of Super5 Lottery",
    about2: "เกี่ยวกับ",
    next: "ต่อไป",
    previous: "ก่อนหน้า",
    for_old_record: "ประวัติการแทง",
    check_here: "ตรวจสอบที่นี่",
    no_new_bet_record: "ไม่มียอดแทง",
    key: "{format}",
    amount: "จำนวนเงิน",
    number: "หมายเลข",
    date: "วันที่",
    confirm: "ยืนยัน",
    amount_to_play: "ใส่จำนวนเงิน",
    number_to_play: "ระบุตัวเลขที่แทง (1-5 หลัก)",
    bet: "แทงหวย",
    comm_rate: "Comm. Rate : {cr}",
    cash: "เงินสด : {cash}",
    close: "ปิด",
    install: "INSTALL",
    install_super5_now: "Install Super5 Now",
    bet_amount: "ยอดแทง",
    bet_list: "โพยยอดแทง",
    win: "ชนะ : {cash}",
    credit: "เครดิต : {credit}",
    "4_click_confirm_to_confirm_your_bet": "4. คลิกเพื่อยืนยันการแทง",
    "3_insert_the_credit_you_want_to_bet": "3. ใส่จำนวนเงิน",
    "2_insert_the_number_you_want_to_bet": "2. ระบุตัวเลขที่แทง (1-5หลัก)",
    "1_click_middle_of_the_button_below":
        "1. กดตรงกลางปุ่ม ของเมนูด้านล่างหน้าจอ",
    how_to_play: "วิธีเล่น",
    result_will_draw_on_utc_8_daily: "ประกาศผลรางวัลเวลา 18:00 (UTC +8) ทุกวัน",
    you_may_withdraw_after_results_released:
        "สามารถถอนเงินได้ หลังจากผลรางวัลออก",
    minimum_bet: "ยอดแทงขั้นต่ำ {expr} บาท เพื่อโอกาสชนะรางวัล",
    "1_win": "บาทละ {odds} บาท",
    hit_1_digit: "แทงถูก 1 ตัวท้าย",
    hit_2_digit: "แทงถูก 2 ตัวท้าย",
    hit_3_digit: "แทงถูก 3 ตัวท้าย",
    hit_4_digit: "แทงถูก 4 ตัวท้าย",
    hit_5_digit: "แทงถูก 5 ตัว",
    dealer: "Dealer",
    agent: "Agent",
    comm: "Comm",
    status: "เนื้อหา",
    rate: "Rate",
    name: "Name",
    code: "Code",
    search_dealer_name: "Search Dealer Name",
    create: "Create",
    dealer_password: "Dealer Password",
    dealer_name: "Dealer Name",
    dealer_username: "Dealer Username",
    dealer_commission_rate: "Dealer Commission Rate",
    dealer_code: "Dealer Code",
    search_agent_name: "Search Agent Name",
    agent_password: "Agent Password",
    agent_username: "Agent Username",
    agent_name: "Agent Name",
    agent_commission_rate: "Agent Commission Rate",
    agent_code: "Agent Code",
    no: "ไม่",
    line_login: "เข้าสู่ระบบด้วยไลน์",
    login: "เข้าสู่ระบบ",
    super_5: "Super 5",
    logout: "ออกจากระบบ",
    how_to_play2: "วิธีเล่น",
    language: "ภาษา",
    past_result: "ผลรางวัลที่ผ่านมา",
    close2: "ปิด",
    failed: "เกิดข้อผิดพลาด ({count})",
    successfully: "สำเร็จ ({count})",
    pagination: "จำนวนหน้า",
    results: "{expr} ผลรางวัล",
    of: "{expr} of {expr}",
    to: "{expr} to {expr}",
    showing: "กำลังแสดง {expr}",
    update: "อัพเดต",
    back: "กลับ",
    confirm_password: "ยืนยันรหัสผ่าน",
    password: "รหัสผ่าน",
    email: "อีเมล",
    username: "ชื่อเข้าระบบ",
    xsearch_credit_xcoin: "ค้นหาเครดิต (เหรียญ)",
    profile: "โปรไฟล์",
    transaction: "การดำเนินการ",
    bet_record: "ประวัติการแทง",
    no_record: "ไม่มีประวัติการแทง",
    set: "วาง",
    bet2: "แทง",
    percent: "เปอร์เซ็นต์",
    out: "ออก",
    number2: "หมายเลข",
    ok: "ตกลง",
    result_number: "Result Number",
    select_result: "Select Result",
    save: "SAVE",
    payout_percent: "Payout Percent",
    payout: "Payout %",
    result_setting: "Result Setting",
    sign_in: "เข้าสู่ระบบ",
    topup_now: "ยืนยัน",
    credit2: "เครดิต",
    "1000_1000_credit": "$1000 = 1000 Credit",
    "100_100_credit": "$100 = 100 Credit",
    "10_10_credit": "$10 = 10 Credit",
    notice: "ประกาศ",
    topup: "เติมเครดิต",
    in: "เข้า",
    today: "วันนี้",
    detail: "รายละเอียด",
    withdraw: "ถอน",
    withdraw_amount: "จำนวนที่ต้องการถอน",
    topup2: "เติมเครดิต",
    topup_amount: "จำนวนเครดิต",
    out2: "ออก",
    in2: "เข้า",
    type: "ประเภท",
    notice_withdraw_will_be_proceed_directly_and_recei:
        "โปรดทราบ : การถอนเงินจะดำเนินการทันที และจะได้รับเงินภายใน 2 ชั่วโมง ขึ้นกับธนาคารนั้นๆ",
    withdraw2: "ถอน",
    amount_to_withdraw: "จำนวนที่ต้องการถอน",
    account_no: "หมายเลขบัญชี",
    account_name: "ชื่อบัญชี",
    select_bank: "เลือกธนาคาร",
    topup_withdraw: "เติมเงิน / ถอนเงิน",
    account_number: "หมายเลขบัญชี",
    owner_name: "ชื่อเจ้าของ",
    high_win_rate: "จ่ายสูงสุดบาทละ 90,000 บาท",
    top_up_your_credit: "เติมเครดิต",
    here: "ที่นี่",
    remark: "Remark",
    bet_notice: "หลังจากกด“ยืนยัน”แล้วจะไม่สามารถแก้ไขได้",
};
