<template>
    <div>
        <div class="text-2xl font-bold">{{ $t("result_setting") }}</div>
        <div class="text-xl">{{ $t("payout") }}</div>
        <div class="my-5 flex">
            <input
                type="number"
                :placeholder="$t('payout_percent')"
                class="p-2 rounded-l text-black w-full disabled:text-white border border-gray-3"
                v-model="setting.value"
            />
            <button
                @click="confirmSetting"
                class="rounded-r w-12 h-12 p-2 bg-amber-500 text-xs font-semibold shadow-sm hover:bg-amber-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-white"
            >
                {{ $t("save") }}
            </button>
        </div>
        <div class="my-5 flex">
            <div class="px-2">{{ $t("today_result") }}</div>
            <input
                type="text"
                :placeholder="$t('today_result')"
                disabled
                readonly
                class="p-2 rounded text-black w-full disabled:text-white disabled:bg-gray-500 border border-gray-3"
                :value="
                    result.number
                        ? result.number + ' (' + result.date + ')'
                        : ' N/A '
                "
            />
        </div>
        <div class="text-xl my-5">
            {{ $t("select_result") }}
            (Total Bet Amount:
            <span class="text-red-500" v-text="admin.getBetAmount"></span>
            )
        </div>
        <div class="my-5 flex">
            <input
                type="text"
                :placeholder="$t('result_number')"
                class="p-2 rounded-l text-black w-full disabled:text-white border border-gray-3"
                v-model="search.number"
                @keyup="checkNumber"
                inputmode="numeric"
                pattern="[0-9]*"
                maxlength="5"
            />
            <button
                @click="confirmResult"
                class="rounded-r w-12 h-12 p-2 bg-amber-500 text-xs font-semibold shadow-sm hover:bg-amber-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-white"
            >
                {{ $t("ok") }}
            </button>
        </div>
        <div
            v-if="admin.getCounters.length > 0"
            class="flex flex-wrap justify-around"
        >
            <div class="w-full my-2">
                <table
                    class="border-collapse border border-slate-500 w-full text-center"
                >
                    <thead>
                        <tr>
                            <th class="border border-slate-600 px-1">
                                {{ $t("number2") }}
                            </th>
                            <th class="border border-slate-600 px-1">
                                {{ $t("out") }}
                            </th>
                            <th class="border border-slate-600 px-1">
                                {{ $t("percent") }}
                            </th>
                            <th class="border border-slate-600 px-1">
                                {{ $t("bet2") }}
                            </th>
                            <th class="border border-slate-600 px-1">
                                {{ $t("set") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(counter, key) in admin.getCounters"
                            :key="key"
                        >
                            <th class="border border-slate-700">
                                {{ counter.number }}
                            </th>
                            <th class="border border-slate-700 px-1">
                                {{ counter.out }}
                            </th>
                            <th class="border border-slate-700">
                                {{ getPercent(counter) }}
                            </th>
                            <th class="border border-slate-700">
                                {{ counter.bet }}
                            </th>
                            <th class="border border-slate-700">
                                <button
                                    @click.prevent="setResult(counter)"
                                    class="rounded w-12 h-12 p-2 bg-amber-500 text-xs font-semibold shadow-sm hover:bg-amber-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-white"
                                >
                                    {{ $t("set") }}
                                </button>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <div class="w-full flex gap-2 justify-center my-2">
                    <button
                        @click="sortCounter"
                        class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                    >
                        <span v-if="!search.sort || search.sort === 'last'">
                            {{ $t("sort_bet_last") }}
                        </span>
                        <span v-else>{{ $t("sort_bet_first") }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="w-full text-red-300 my-2">{{ $t("no_record") }}</div>
        <!--        <div v-if="admin.getCounters.length > 0" class="w-full flex gap-2">-->
        <!--            <button-->
        <!--                @click="prevPage"-->
        <!--                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"-->
        <!--                :class="{-->
        <!--                    invisible: search.page <= 1,-->
        <!--                }"-->
        <!--            >-->
        <!--                <span v-if="search.page > 1">{{ $t("previous") }}</span>-->
        <!--                <span v-else></span>-->
        <!--            </button>-->
        <!--            <button-->
        <!--                @click="nextPage"-->
        <!--                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"-->
        <!--                :class="{-->
        <!--                    invisible: search.page >= admin.getCounters.last_page,-->
        <!--                }"-->
        <!--            >-->
        <!--                <span v-if="search.page < admin.getCounters.last_page">{{-->
        <!--                    $t("next")-->
        <!--                }}</span>-->
        <!--                <span v-else></span>-->
        <!--            </button>-->
        <!--        </div>-->
    </div>
</template>
<script setup>
import { inject, reactive } from "vue";
import { useAdminStore, useMainStore } from "@/store/index.js";

const emitter = inject("emitter");
const admin = useAdminStore();
const main = useMainStore();
let search = reactive({
    number: null,
    page: 1,
    sort: "last",
});
let form = reactive({
    number: null,
});

let setting = reactive({
    key: "result_percent",
    value: null,
});

admin.loadSetting();
admin.loadResult();
admin.loadCounter(search);
const getPercent = (counter) => {
    if (counter.out > 0) {
        const percent = (parseFloat(counter.out) / admin.getBetAmount) * 100;
        const value = percent > 100 ? "> 100" : percent.toFixed(2);
        return value + "%";
    }
    return "0%";
};
const checkNumber = () => {
    form.number = search.number.toString();
    admin.loadCounter(search);
};

const confirmResult = () => {
    admin.createResult(form);
};
const confirmSetting = () => {
    admin.updateSetting(setting);
};
const setResult = (counter) => {
    form.number = counter.number.toString();
    search.number = counter.number.toString();
};
// const nextPage = () => {
//     if (search.page < admin.getCounters.last_page) {
//         search.page++;
//         admin.loadCounter(search);
//         emitter.emit("toTop");
//     }
// };
// const prevPage = () => {
//     if (search.page > 1) {
//         search.page--;
//         admin.loadCounter(search);
//         emitter.emit("toTop");
//     }
// };
let result = reactive({
    number: null,
    date: null,
});
const sortCounter = () => {
    search.sort = search.sort === "last" ? "first" : "last";
    admin.loadCounter(search);
    emitter.emit("toTop");
};
admin.$subscribe((mutation, state) => {
    if (state.settings) {
        const resultPercent = state.settings.filter(
            (setting) => setting.key === "result_percent"
        );
        if (resultPercent.length > 0) {
            setting.value = resultPercent[0].value;
        }
    }
    if (state.results && state.results.today) {
        result.number = state.results.today.result;
        result.date = main.getDate(state.results.today.created_at);
        console.log(result);
    }
});
</script>
