<template>
    <div>
        <div class="text-2xl font-bold">{{ $t("bet") }}</div>
        <div class="flex flex-col gap-5 pt-5 relative">
            <input
                type="number"
                :placeholder="$t('number_to_play')"
                class="p-2 rounded text-black w-full border border-gray-3"
                v-model="form.number"
            />
            <input
                type="number"
                :placeholder="$t('amount_to_play')"
                class="p-2 rounded text-black w-full border border-gray-3"
                v-model="form.amount"
            />
            <button
                @click="confirmPlay"
                class="rounded text-center w-full bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
            >
                {{ $t("confirm") }}
            </button>
        </div>
        <div class="flex flex-col font-bold text-center w-full my-5">
            <div class="flex w-full">
                <div class="w-1/3">{{ $t("date") }}</div>
                <div class="w-1/3">{{ $t("number") }}</div>
                <div class="w-1/3">{{ $t("amount") }}</div>
            </div>
            <template v-if="main.getBets.total > 0">
                <div
                    v-for="(bet, key) in main.getBets.data"
                    :key="'bet-' + key"
                    class="flex w-full"
                >
                    <!--suppress AllyHtmlVueInspection -->
                    <div class="w-1/3">
                        {{ main.getDate(bet.created_at) }}
                    </div>
                    <div class="w-1/3">
                        {{ bet.number }}
                    </div>
                    <div class="w-1/3">
                        {{ bet.amount }}
                    </div>
                </div>
            </template>
            <div v-else class="w-full text-red-300 my-2">
                {{ $t("no_new_bet_record") }}
                <router-link
                    :to="{ name: 'record' }"
                    class="text-blue-400 hover:text-blue-500"
                >
                    {{ $t("check_here") }}
                </router-link>
                {{ $t("for_old_record") }}
            </div>
        </div>
        <div class="w-full flex gap-2 py-2">
            <button
                @click="prevPage"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    invisible: search.page <= 1,
                }"
            >
                <span v-if="search.page > 1">{{ $t("previous") }}</span>
                <span v-else></span>
            </button>
            <button
                @click="nextPage"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    invisible: search.page >= main.bets.last_page,
                }"
            >
                <span v-if="search.page < main.bets.last_page">{{
                    $t("next")
                }}</span>
                <span v-else></span>
            </button>
        </div>
    </div>
</template>
<script setup>
import { inject, reactive } from "vue";
import { useMainStore } from "@/store/index.js";

const emitter = inject("emitter");
const main = useMainStore();
const form = reactive({
    number: null,
    amount: null,
});
const confirmPlay = () => {
    main.createBet(form);
};

let search = reactive({
    page: 1,
});
main.loadBet(search);
const nextPage = () => {
    if (search.page < main.getBets.last_page) {
        search.page++;
        main.loadBet(search);
        emitter.emit("toTop");
    }
};
const prevPage = () => {
    if (search.page > 1) {
        search.page--;
        main.loadBet(search);
        emitter.emit("toTop");
    }
};
</script>
