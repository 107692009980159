<template>
    <div v-if="main.getResults.today">
        <div class="flex flex-col">
            <div>
                <!--suppress AllyHtmlVueInspection -->
                <div class="font-bold">
                    {{ main.getDate(main.getResults.today.created_at) }}
                </div>
                <div class="font-bold text-2xl">
                    {{ main.getResults.today.result }}
                </div>
                <div class="flex flex-col gap-2">
                    <div
                        v-for="(result, key) in main.getResults.today.data"
                        :key="key"
                        class="flex flex-row w-full gap-2"
                    >
                        <div
                            class="rounded border-2 border-red-300 w-1/2 text-right px-2"
                        >
                            {{ result.number }}
                        </div>
                        <div
                            class="rounded border-2 border-red-300 w-1/2 text-left px-2"
                        >
                            {{ $t("hit_" + result.number.length + "_digit") }}
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    {{
                        $t("result_will_draw_on_utc_8_daily", {
                            expr: main.getResults
                                ? main.getResults.draw_time
                                : "",
                        })
                    }}
                </div>
            </div>
            <div class="text-center mt-5 font-bold text-xl">
                {{
                    $t("minimum_bet", {
                        expr: main.getOdds ? main.getOdds.minimum_bet : "",
                    })
                }}
            </div>
            <div class="text-center mt-5 font-bold text-xl">
                {{ $t("you_may_withdraw_after_results_released") }}
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";

const main = useMainStore();
main.loadResult();
main.loadOdds();
</script>
