import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import axios from "@/axios.js";
import router from "@/router";
import { useMainStore } from "@/store/main.js";

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        token: useStorage("token", null),
        user: useStorage("user", {}),
    }),
    getters: {
        getUser: (state) => state.user,
        getToken: (state) => state.token,
    },
    actions: {
        async login(form) {
            await axios.post("login", form).then(({ data }) => {
                this.token = data.token;
                this.user = data.user;
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_bet" }));
            });
        },
        async assignUser(form) {
            if (this.token) {
                router.isReady().then(() => router.replace({ name: "home" }));
                return;
            }
            await axios.post("oauth/token", form).then(({ data, success }) => {
                if (success) {
                    this.token = data.token;
                    this.user = data.user;
                    router
                        .isReady()
                        .then(() => router.replace({ name: "home" }));
                } else {
                    router
                        .isReady()
                        .then(() => router.replace({ name: "login" }));
                }
            });
        },
        async updatePassword(form) {
            await axios.post("password/update", form).then(({ data }) => {
                this.user = data;
                router.isReady().then(() => router.replace({ name: "home" }));
            });
        },
        me() {
            const main = useMainStore();
            return axios.get("me").then(({ data, success }) => {
                if (success) {
                    this.user = data;
                    main.setLanguage(data.lang);
                }
            });
        },
        updateMe(form) {
            return axios.post("me", form).then(({ data }) => {
                if (data) {
                    this.user = data;
                }
            });
        },
        updateRealName(form) {
            return axios.post("me/real_name", form).then(({ data }) => {
                if (data) {
                    this.user = data;
                }
            });
        },
        logout() {
            const main = useMainStore();
            main.setModal();
            return axios.post("logout").finally(() => {
                this.token = null;
                this.user = {};
                router.replace({ name: "login" });
            });
        },
    },
});
