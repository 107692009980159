<template>
    <div>
        <div class="text-2xl font-bold">{{ $t("withdraw") }}</div>
        <div class="absolute top-2 left-0">
            <router-link
                :to="{ name: 'wallet' }"
                class="text-xl rounded-full bg-orange-600 p-2 block"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="w-8 h-8 text-gray-50"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M16 256a240 240 0 1 1 480 0A240 240 0 1 1 16 256zm496 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM234.3 146.3l-104 104c-3.1 3.1-3.1 8.2 0 11.3l104 104c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L155.3 264 376 264c4.4 0 8-3.6 8-8s-3.6-8-8-8l-220.7 0 90.3-90.3c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0z"
                    />
                </svg>
            </router-link>
        </div>
        <div class="flex flex-col gap-5 pt-5">
            <select
                class="p-2 rounded text-black w-full"
                v-model="form.bank_id"
            >
                <option value="">{{ $t("select_bank") }}</option>
                <option
                    v-for="(bank, key) in main.getWithdrawBanks"
                    :key="'dealer-' + key"
                    :value="bank.id"
                >
                    {{ bank.bank_name }}
                </option>
            </select>
            <input
                type="text"
                disabled
                :placeholder="$t('owner_name')"
                class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                v-model="form.account_name"
            />
            <input
                type="number"
                :placeholder="$t('account_number')"
                class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                v-model="form.account_no"
            />
            <input
                type="number"
                @keyup="checkWithdraw"
                :placeholder="$t('amount_to_withdraw')"
                class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                :class="{
                    'bg-gray-500 hover:bg-gray-600': withdraw < 100,
                    'bg-green-600 hover:bg-green-700': withdraw > 100,
                }"
                v-model="form.amount"
            />
            <button
                @click="requestNow"
                class="rounded text-center w-full bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
            >
                {{ $t("confirm") }}
            </button>
        </div>
    </div>
</template>
<script setup>
import { useAuthStore, useMainStore } from "@/store/index.js";
import { reactive } from "vue";

const main = useMainStore();
const auth = useAuthStore();
let form = reactive({
    amount: null,
    bank_id: null,
    account_no: null,
    account_name: null,
});
const withdraw = auth.getUser.cash;
main.loadBanks();
const requestNow = () => {
    main.createWithdraw(form);
};
const checkWithdraw = (e) => {
    if (e.target.value >= auth.getUser.cash) {
        form.amount = auth.getUser.cash;
    }
};
</script>
