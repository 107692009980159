import { useAuthStore, useMainStore } from "@/store";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API + "/api",
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=utf-8;",
        Accept: "application/json",
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const auth = useAuthStore();
        const main = useMainStore();
        // Do something before request is sent
        // console.log(auth.getToken);
        if (auth.getToken) {
            config.headers["Authorization"] = "Bearer " + auth.getToken;
        }
        main.setLoading(true);
        return config;
    },
    (error) => {
        // Do something with request error
        const main = useMainStore();
        main.setLoading(false);
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    ({ data }) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // const auth = useAuthStore();
        const main = useMainStore();
        if (data && data.code && data.code !== 200) {
            main.addMessage(data);
        }
        main.setLoading(false);
        return data;
    },
    ({ response }) => {
        const main = useMainStore();
        main.setLoading(false);
        if (
            response &&
            response.data &&
            response.data.message === "Unauthenticated."
        ) {
            const auth = useAuthStore();
            if (auth.getToken) {
                auth.logout();
            }
        }

        if (response && response.data) {
            main.addMessage(response.data);
        }
        main.setLoading(false);
        if (response.data) {
            return Promise.reject(response.data);
        }
        return Promise.reject(response);
    }
);
export default axiosInstance;
