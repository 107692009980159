<template>
    <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            @mouseenter="stopCountdown = true"
            @mouseleave="stopCountdown = false"
            class="z-50 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <svg
                            v-if="props.data.success"
                            class="h-6 w-6 text-green-500"
                            fill="currentColor"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M235.3 331.3C229.1 337.6 218.9 337.6 212.7 331.3L148.7 267.3C142.4 261.1 142.4 250.9 148.7 244.7C154.9 238.4 165.1 238.4 171.3 244.7L224 297.4L340.7 180.7C346.9 174.4 357.1 174.4 363.3 180.7C369.6 186.9 369.6 197.1 363.3 203.3L235.3 331.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"
                            />
                        </svg>
                        <svg
                            v-else
                            class="h-6 w-6 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"
                            />
                        </svg>
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p
                            v-if="props.data.success"
                            class="text-sm font-medium text-green-900"
                        >
                            {{ $t("successfully", { count: count }) }}
                        </p>
                        <p v-else class="text-sm font-medium text-red-900">
                            {{ $t("failed", { count: count }) }}
                        </p>
                        <p
                            class="mt-1 text-sm text-gray-500"
                            v-html="props.data.message"
                        ></p>
                        <div
                            v-if="props.data.errors"
                            class="flex flex-col text-red-500"
                        >
                            <div
                                v-for="(errors, field) in props.data.errors"
                                :key="field"
                                class="flex flex-col"
                            >
                                <div class="font-bold w-20">
                                    {{ handleField(field) }}
                                </div>
                                <div
                                    v-for="(error, key) in errors"
                                    :key="key"
                                    class="flex-1"
                                >
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                        <button
                            class="bg-white rounded-md inline-flex text-gray-500 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fc-secondary-3"
                            type="button"
                            @click="remove"
                        >
                            <span class="sr-only">{{ $t("close2") }}</span>
                            <svg
                                class="w-5 h-5"
                                viewBox="0 0 320 512"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script setup>
import { ref } from "vue";
import { useMainStore } from "@/store";

let stopCountdown = ref(false);
let count = ref(10);
const main = useMainStore();
// eslint-disable-next-line
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
});

const handleField = (msg) => {
    return msg.replace("_", " ").charAt(0).toUpperCase() + msg.slice(1);
};
var interval = setInterval(() => {
    if (!stopCountdown.value) {
        count.value--;
    }

    if (count.value < 1) {
        main.removeMessage(props.index);
        clearInterval(interval);
    }
}, 300);
const remove = () => {
    main.removeMessage(props.index);
};
</script>
