export default {
    about: "关于",
    super5_lottery_pvt_ltd_super5_lottery_formerly_kno:
        "Super Lottery Pvt Ltd (Super Lottery), formerly known as Super Lottery Pvt Ltd was first incorporated by the Government of Holland.\n" +
        "Today, Super Lottery is the largest number forecast operator in the country both in terms of number of outlets and product offerings. It has approximately 680 sales outlets offering a total of 8 games i.e. Super 4, Super 4 Jackpot, Super 4 Zodiac, Super 5, Super 6, Super 6/45, Super 6/55 and Super 6/65. It is also the sole licensed national lotto operator. Super Lottery has a steadfast commitment as a responsible corporate citizen. Since its inception, substantial contributions have been made in various corporate social responsibility areas such as charity, community sports, education and cultural promotion. In line with our philosophy of promoting a caring and sustainable society, we constantly lend our hands to the underprivileged. We continue to support and nurture sporting excellence in the country through huge annual contributions to the National Sports Council and active participation in numerous community sports initiatives.",
    origin_of_super5_lottery: "Super5彩票的起源",
    about2: "关于",
    next: "下一个",
    previous: "之前",
    for_old_record: "对于旧记录.",
    check_here: "检查这里",
    no_new_bet_record: "没有新的投注记录.",
    key: "{format}",
    amount: "金额",
    number: "号码",
    date: "日期",
    confirm: "确认",
    amount_to_play: "下注的金额",
    number_to_play: "下注的号码",
    bet: "下注",
    comm_rate: "佣金. 比率 : {cr}",
    cash: "现金 : {cash}",
    close: "关闭",
    install: "安装",
    install_super5_now: "立即安装 Super5",
    bet_amount: "下注的金额",
    bet_list: "下注的列表",
    win: "赢取 : {cash}",
    credit: "积分 : {credit}",
    "4_click_confirm_to_confirm_your_bet": "4. 点击【确认】确认您的投注.",
    "3_insert_the_credit_you_want_to_bet": "3. 输入您想要下注的积分.",
    "2_insert_the_number_you_want_to_bet": "2. 输入您要下注的号码.",
    "1_click_middle_of_the_button_below": "1. 点击下面中间的按钮.",
    how_to_play: "如何下注",
    result_will_draw_on_utc_8_daily: "结果将于每日 18:00 (UTC + 8) 开始抽奖",
    you_may_withdraw_after_results_released: "成绩公布后您可以进行提款.",
    minimum_bet: "只需投注至少 ${expr} 泰铢，就有机会赢取奖金",
    "1_win": "1 泰铢能够赢取 {odds}",
    hit_1_digit: "对中最后1个号码",
    hit_2_digit: "对中最后2个号码",
    hit_3_digit: "对中最后3个号码",
    hit_4_digit: "对中最后4个号码",
    hit_5_digit: "对中全5个号码",
    dealer: "Dealer",
    agent: "Agent",
    comm: "佣金.",
    status: "状态",
    rate: "比率",
    name: "姓名",
    code: "代码",
    search_dealer_name: "搜索Dealer名称",
    create: "创建",
    dealer_password: "Dealer 密码",
    dealer_name: "Dealer 姓名",
    dealer_username: "Dealer 用户名",
    dealer_commission_rate: "Dealer 佣金率",
    dealer_code: "Dealer 代码",
    search_agent_name: "搜索 Agent 姓名",
    agent_password: "Agent 密码",
    agent_username: "Agent 用户名",
    agent_name: "Agent 姓名",
    agent_commission_rate: "Agent 佣金率",
    agent_code: "Agent 代码",
    no: "没有",
    line_login: "Line 登录",
    login: "登录",
    super_5: "Super 5",
    logout: "退出",
    how_to_play2: "如何下注",
    language: "语言",
    past_result: "过往开彩成绩",
    close2: "关闭",
    failed: "失败! ({count})",
    successfully: "成功! ({count})",
    pagination: "分页",
    results: "{expr} 成绩",
    of: "{expr} of {expr}",
    to: "{expr} to {expr}",
    showing: "显示中 {expr}",
    update: "更新",
    back: "后退",
    confirm_password: "确认密码",
    password: "密码",
    email: "电子邮件",
    username: "用户名",
    profile: "简介",
    transaction: "交易",
    bet_record: "投注记录",
    no_record: "无记录.",
    set: "设定",
    bet2: "下注",
    percent: "百分率",
    out: "出",
    number2: "号码",
    ok: "确认",
    result_number: "成绩号码",
    select_result: "选择成绩",
    save: "保存",
    payout_percent: "支付百分比",
    payout: "支付 %",
    result_setting: "设定成绩",
    sign_in: "登入",
    topup_now: "立即充值",
    credit2: "积分",
    "1000_1000_credit": "$1000 = 1000 积分",
    "100_100_credit": "$100 = 100 积分",
    "10_10_credit": "$10 = 10 积分",
    notice: "通知",
    topup: "充值",
    in: "进",
    today: "今天",
    detail: "详情",
    withdraw: "提款",
    withdraw_amount: "提取的金额",
    topup2: "充值",
    topup_amount: "充值的金额",
    out2: "出",
    in2: "进",
    type: "类型",
    notice_withdraw_will_be_proceed_directly_and_recei:
        "通知: 提款直接进行，2小时内到账.",
    withdraw2: "提款",
    amount_to_withdraw: "提取的金额",
    account_no: "帐户号码",
    account_name: "帐户姓名",
    select_bank: "选择银行",
    topup_withdraw: "充值/提款",
    account_number: "银行帐号",
    owner_name: "帐户姓名",
    high_win_rate: "高赔率，1泰铢就有机会赢取{expr}泰铢",
    top_up_your_credit: "",
    here: "点此充值",
    remark: "备注",
    action: "来源",
    bet_notice: "点击确认后无法取消",
};
