<template>
    <div class="flex flex-wrap justify-around py-5">
        <button
            :class="{
                'bg-orange-500 hover:bg-orange-600': tab !== 'bet_record',
                'bg-green-600 hover:bg-green-700': tab === 'bet_record',
            }"
            class="text-lg rounded py-2 px-3 text-white"
            type="button"
            @click="tab = 'bet_record'"
        >
            {{ $t("bet_record") }}
        </button>
        <button
            :class="{
                'bg-orange-500 hover:bg-orange-600': tab !== 'transaction',
                'bg-green-600 hover:bg-green-700': tab === 'transaction',
            }"
            class="text-lg rounded py-2 px-3 text-white"
            type="button"
            @click="tab = 'transaction'"
        >
            {{ $t("transaction") }}
        </button>
    </div>
    <div
        v-if="tab === 'bet_record'"
        class="flex flex-col justify-between h-full text-sm"
    >
        <div v-if="main.getBetRecords.total > 0" class="text-center font-bold">
            {{ main.getBetRecords.current_page }} /
            {{ main.getBetRecords.last_page }}
        </div>
        <div class="font-bold text-center w-full flex-grow">
            <table class="table table-auto w-full border-0">
                <thead>
                    <th>{{ $t("date") }}</th>
                    <th>{{ $t("number") }}</th>
                    <th>{{ $t("amount") }}</th>
                </thead>
                <tbody>
                    <template
                        v-if="
                            main.getBetRecords && main.getBetRecords.total > 0
                        "
                    >
                        <tr
                            v-for="(bet, key) in main.getBetRecords.data"
                            :key="'bet-' + key"
                        >
                            <td>
                                {{ main.getDate(bet.created_at) }}
                            </td>
                            <td>
                                {{ bet.number }}
                            </td>
                            <td>
                                {{ bet.amount }}
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <th class="text-red-300 my-2" colspan="3">
                                {{ $t("no_new_bet_record") }}
                                <router-link
                                    :to="{ name: 'history' }"
                                    class="text-blue-400"
                                >
                                    {{ $t("check_here") }}
                                </router-link>
                                {{ $t("for_old_record") }}
                            </th>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div
            v-if="main.getBetRecords && main.getBetRecords.total > 0"
            class="w-full flex gap-2 py-2"
        >
            <button
                :class="{
                    invisible: form.page <= 1,
                }"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                @click="prevPage"
            >
                {{ $t("previous") }}
            </button>
            <button
                :class="{
                    invisible: form.page >= main.getBetRecords.last_page,
                }"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                @click="nextPage"
            >
                {{ $t("next") }}
            </button>
        </div>
    </div>
    <div
        v-if="tab === 'transaction'"
        class="flex flex-col justify-between h-full text-sm"
    >
        <div
            v-if="main.getTransactions.total > 0"
            class="text-center font-bold"
        >
            {{ main.getTransactions.current_page }} /
            {{ main.getTransactions.last_page }}
        </div>
        <div class="font-bold text-center w-full flex-grow">
            <table class="table table-auto w-full border-0">
                <thead>
                    <tr class="font-bold">
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("status") }}</th>
                        <th>{{ $t("type") }}</th>
                        <th>{{ $t("in") }}</th>
                        <th>{{ $t("out") }}</th>
                    </tr>
                </thead>
                <tbody class="overflow-hidden overflow-y-auto">
                    <template
                        v-if="
                            main.getTransactions &&
                            main.getTransactions.total > 0
                        "
                    >
                        <tr
                            v-for="(trans, key) in main.getTransactions.data"
                            :key="'bet-' + key"
                            :class="{
                                'text-green-500': trans.action === 'win',
                            }"
                        >
                            <td>
                                {{ main.getDate(trans.created_at) }}
                            </td>
                            <td>
                                {{ getAction(trans.action) }}
                            </td>
                            <td class="uppercase">
                                {{ getType(trans) }}
                            </td>
                            <td class="uppercase">
                                {{ trans.in }}
                            </td>
                            <td>
                                {{ trans.out }}
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <th class="text-red-300 my-2" colspan="7">
                                {{ $t("no_record") }}
                            </th>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div
            v-if="main.getTransactions.total > 0"
            class="w-full flex gap-2 py-2"
        >
            <button
                :class="{ invisible: formTransaction.page <= 1 }"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                @click="prevTransactionPage"
            >
                <span v-if="formTransaction.page > 1">{{
                    $t("previous")
                }}</span>
                <span v-else></span>
            </button>
            <button
                :class="{
                    invisible:
                        formTransaction.page >= main.getTransactions.last_page,
                }"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                @click="nextTransactionPage"
            >
                <span
                    v-if="formTransaction.page < main.getTransactions.last_page"
                >
                    {{ $t("next") }}
                </span>
                <span v-else></span>
            </button>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";
import { inject, reactive, ref } from "vue";

const emitter = inject("emitter");
const main = useMainStore();
let form = reactive({
    page: 1,
});

let formTransaction = reactive({
    page: 1,
});

main.loadBetRecord(form);
main.loadTransactions(formTransaction);
const tab = ref("bet_record");
const nextPage = () => {
    if (form.page <= main.getBetRecords.last_page) {
        form.page++;
        main.loadBetRecord(form);
        emitter.emit("toTop");
    }
};
const prevPage = () => {
    if (form.page > 1) {
        form.page--;
        main.loadBetRecord(form);
        emitter.emit("toTop");
    }
};

const nextTransactionPage = () => {
    if (formTransaction.page < main.getTransactions.last_page) {
        formTransaction.page++;
        main.loadTransactions(formTransaction);
        emitter.emit("toTop");
    }
};
const prevTransactionPage = () => {
    if (formTransaction.page > 1) {
        formTransaction.page--;
        main.loadTransactions(formTransaction);
        emitter.emit("toTop");
    }
};
const getAction = (action) => {
    switch (action) {
        case "win":
            return "Win";
        case "bet":
            return "Bet";
        case "commission":
            return "Comm";
        case "topup":
            return "IN";
        case "withdraw":
            return "OUT";
        default:
            return "Other";
    }
};
const getType = (bet) => {
    if (bet.type === "cash") {
        return bet.transferable?.match;
    }
    return bet.type;
};
</script>
