<template>
    <component :is="main.getLayout">
        <div
            aria-live="assertive"
            class="fixed z-50 inset-0 flex items-end px-4 pointer-events-none md:p-6 sm:items-start"
        >
            <div
                class="w-full flex flex-col items-center space-y-4 sm:items-end"
            >
                <template v-for="(message, key) in getMessages" :key="key">
                    <notification :data="message" :index="key" />
                </template>
            </div>
        </div>
        <modal />
        <router-view />
    </component>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore, useMainStore } from "@/store";
import Notification from "@/components/Notification.vue";
import Modal from "@/components/Modal.vue";
import { inject, nextTick, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";

const main = useMainStore();
const auth = useAuthStore();
const emitter = inject("emitter");
const i18n = useI18n();
i18n.locale.value = main.getLanguage;
emitter.on("changeLanguage", (lang) => {
    auth.updateMe({ lang });
    main.setLanguage(lang);
    i18n.locale.value = lang;
});
const { getMessages } = storeToRefs(main);
main.connectSocket();
// eslint-disable-next-line no-undef
Socket.connector.pusher.connection.bind("state_change", ({ current }) => {
    if (current === "connecting" || current === "unavailable") {
        // const stats = current[0].toUpperCase() + current.slice(1);
        // main.addMessage({
        //   message: "Websocket " + stats + ".",
        //   success: false,
        // });
        main.setSocket();
        main.resetChannels();
    }
});

// eslint-disable-next-line no-undef
Socket.connector.pusher.connection.bind("connected", (event) => {
    // if (auth.getToken) {
    //   main.setSocket(event);
    //   // eslint-disable-next-line no-undef
    //   main.subscribeChannel(event);
    // }
    main.setSocket(event);
    main.subscribeChannel(event);
});

// eslint-disable-next-line no-undef
Socket.connector.pusher.connection.bind("disconnected", () => {
    nextTick(() => {
        // main.addMessage({
        //   message: "Websocket Disconnected.",
        //   success: false,
        // });
        main.setSocket();
        main.resetChannels();
    });
});
auth.$subscribe((mutation, state) => {
    if (state.token && !main.isSocketConnected) {
        // eslint-disable-next-line no-undef
        Socket.connector.pusher.connect();
    }

    if (!state.token && main.isSocketConnected) {
        // eslint-disable-next-line no-undef
        Socket.disconnect();
    }
});

onBeforeUnmount(() => {
    // eslint-disable-next-line no-undef
    Socket.disconnect();
});
</script>
