<template>
    <div>
        <div
            v-if="main.getOdds && main.getOdds.higher_odds"
            class="flex flex-col"
        >
            <div class="flex flex-col">
                <div class="text-lg text-pink-400 font-bold py-2 uppercase">
                    {{
                        $t("high_win_rate", {
                            expr: main.getOdds.higher_odds.odds,
                        })
                    }}
                </div>
                <div
                    v-for="(result, key) in main.getOdds.odds"
                    :key="key"
                    class="flex flex-col w-full py-2 font-bold text-xl border border-gray-400 first:rounded-tr first:rounded-tl last:rounded-bl last:rounded-br bg-gray-300"
                >
                    <div class="px-2 text-gray-800">
                        {{ $t("hit_" + result.hit + "_digit") }}
                    </div>
                    <div class="px-2 text-red-800">
                        {{ $t("1_win", { odds: result.odds }) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";

const main = useMainStore();
main.loadOdds();
</script>
