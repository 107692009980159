<template>
    <div class="w-full h-screen flex flex-row items-center">
        <div class="mx-auto">
            <div>
                <img
                    src="@/assets/logo.png"
                    class="w-28 h-28 mx-auto"
                    :alt="$t('super_5')"
                />
            </div>
            <div
                class="py-5 text-center text-2xl font-bold font-sans uppercase"
            >
                {{ $t("login") }}
            </div>
            <div>
                <label
                    for="username"
                    class="block text-sm font-medium leading-6"
                >
                    {{ $t("username") }}
                </label>
                <div class="mt-2">
                    <input
                        id="username"
                        v-model="form.username"
                        type="text"
                        autocomplete="username"
                        required=""
                        class="block w-full rounded-md p-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 border border-gray-3"
                    />
                </div>
            </div>
            <div>
                <label
                    for="password"
                    class="block text-sm font-medium leading-6"
                >
                    {{ $t("password") }}
                </label>
                <div class="mt-2">
                    <input
                        id="password"
                        v-model="form.password"
                        type="password"
                        autocomplete="current-password"
                        required=""
                        class="block w-full px-2 text-black rounded-md border-0 bg-white p-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 border border-gray-3"
                    />
                </div>
            </div>
            <div class="mt-2">
                <button
                    @click="login"
                    class="flex w-full justify-center rounded-md bg-amber-500 px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 text-white"
                >
                    {{ $t("sign_in") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAuthStore } from "@/store/index.js";
import { reactive } from "vue";

const form = reactive({
    username: null,
    password: null,
});
const auth = useAuthStore();
const login = () => {
    auth.login(form);
};
</script>
