import { createRouter, createWebHistory } from "vue-router";
import frontend from "@/router/frontend";
import backend from "@/router/backend";
import { useAuthStore, useMainStore } from "@/store";

const routes = [
    ...frontend,
    ...backend,
    {
        path: "/:catchAll(.*)*",
        name: "PageNotFound",
        redirect: { name: "home" },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.VITE_BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // console.log("to");
    // console.log(to);
    // console.log("from");
    // console.log(from);
    const requireAuth = to.meta.auth;
    const { getToken, me, getUser } = useAuthStore();
    const { changeLayout } = useMainStore();
    if (getToken) {
        me();
    }

    switch (true) {
        case getToken && to.meta.admin:
            changeLayout("admin_layout");
            break;
        case getToken && !to.meta.admin:
            changeLayout("default_layout");
            break;
        case !to.meta.admin && !to.meta.auth:
        default:
            changeLayout("empty_layout");
            break;
    }

    switch (true) {
        case requireAuth && !getToken:
            changeLayout("empty_layout");
            next({ name: "login" });
            return;
        case !to.meta.admin &&
            (getUser.is_admin || getUser.is_dealer || getUser.is_agent):
            changeLayout("admin_layout");
            next({ name: "admin_bet" });
            return;
        case getToken && !requireAuth:
        case to.meta.admin &&
            !getUser.is_admin &&
            !getUser.is_dealer &&
            !getUser.is_agent:
            changeLayout("default_layout");
            next({ name: "home" });
            return;
        default:
            next();
    }
});

export default router;
