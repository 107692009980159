import { defineStore } from "pinia";
import axios from "@/axios.js";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { useStorage } from "@vueuse/core";
import moment from "moment";
import { useAuthStore } from "@/store/auth.js";

export const useMainStore = defineStore({
    id: "main",
    state: () => {
        return {
            loading: false,
            isSearch: false,
            language: useStorage("language", "en"),
            layout: "empty_layout",
            socket: null,
            channels: [],
            results: [],
            odds: [],
            bets: [],
            betRecords: [],
            withdrawBanks: [],
            histories: [],
            transactions: [],
            detail: null,
            messages: [],
            topups: [],
            invoice: {},
            topupOrders: [],
            payments: [],
            withdraws: [],
            deferredPrompt: null,
            totalWithdraws: [],
            totalPayments: [],
            modal: {
                data: null,
                type: null,
                amount: null,
                transaction: null,
            },
        };
    },
    getters: {
        getLayout: (state) => state.layout,
        isLoading: (state) => state.loading,
        getSocket: (state) => state.socket,
        getDeferredPrompt: (state) => state.deferredPrompt,
        isSocketConnected: (state) => !!state.socket,
        getChannels: (state) => state.channels,
        getResults: (state) => state.results,
        getOdds: (state) => state.odds,
        getBets: (state) => state.bets,
        getLanguage: (state) => state.language,
        getWithdrawBanks: (state) => state.withdrawBanks,
        getBetRecords: (state) => state.betRecords,
        getTransactions: (state) => state.transactions,
        getHistories: (state) => state.histories,
        getDetail: (state) => state.detail,
        getMessages: (state) => state.messages,
        getInvoice: (state) => state.invoice,
        getTopupOrders: (state) => state.topupOrders,
        getPurchaseOrders: (state) => state.purchaseOrders,
        getPackages: (state) => state.packages,
        getModal: (state) => state.modal,
        getPayments: (state) => state.payments,
        getWithdraws: (state) => state.withdraws,
        getTotalWithdraws: (state) => state.totalWithdraws,
        getTotalPayments: (state) => state.totalPayments,
    },
    actions: {
        getDate(date, format = "DD MMM YYYY") {
            return moment(date, "YYYY-MM-DDThh:mm:ssTZD", this.language).format(
                format
            );
        },
        changeLayout(template = "empty_layout") {
            this.layout = template;
        },
        setLanguage(value = "en") {
            this.language = value;
        },
        setDeferredPrompt(value = null) {
            this.deferredPrompt = value;
        },
        setModal(type = "fpay", data = null, amount = null) {
            this.modal.data = data;
            this.modal.type = type;
            this.modal.amount = amount;
        },
        setLoading(status = false) {
            this.loading = status;
        },
        setSocket(connect = null) {
            this.socket = connect;
        },
        resetChannels() {
            this.channels = [];
        },
        addChannels(channel) {
            this.channels.push(channel);
        },
        subscribeChannel(event, name = "spr5") {
            if (this.channels.indexOf((channel) => channel.name === name) < 0) {
                // eslint-disable-next-line no-undef
                const channelPriv = Socket.channel(name);
                this.addChannels(channelPriv);
                channelPriv
                    .stopListening(".result")
                    .listen(".result", (message) => {
                        if (message.status) {
                            this.addMessage({
                                message: "Result finished loaded.",
                                success: true,
                            });
                            this.setLoading(false);
                        }
                    });

                //channel.whisper("typing", { name: true });
                // channel.notification((notification) => {
                //     console.log(notification);
                // });

                this.setSocket(event.socket_id);
                // this.addMessage({
                //     message: "Websocket Connected.",
                //     success: true,
                // });
            }
        },
        connectSocket() {
            if (!window.Pusher) {
                window.Pusher = Pusher;
            }
            if (!window.Socket) {
                window.Socket = new Echo({
                    broadcaster: "pusher",
                    namespace: "App.Events",
                    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                    wsHost: process.env.VUE_APP_PUSHER_HOST,
                    wsPort: process.env.VUE_APP_PUSHER_PORT,
                    wssPort: process.env.VUE_APP_PUSHER_PORT,
                    key: process.env.VUE_APP_PUSHER_APP_KEY,
                    secret: process.env.VUE_APP_PUSHER_APP_SECRET,
                    enabledTransports: ["ws", "wss"],
                    unavailableTimeout: 2000,
                    pongTimeout: 6000,
                    forceTLS: true,
                    encrypted: true,
                    disableStats: true,
                    encryptionMasterKeyBase64:
                        process.env.VUE_APP_PUSHER_APP_ENCRYPT,
                    // authorizer: (channel, options) => {
                    // 	const auth = useAuthStore();
                    //
                    // 	options.auth.headers["Authorization"] = "Bearer " + auth.getToken;
                    // 	return {
                    // 		authorize: (socketId, callback) => {
                    // 			axios
                    // 			.post("/broadcasting/auth", {
                    // 				socket_id: socketId,
                    // 				channel_name: channel.name,
                    // 			})
                    // 			.then((response) => {
                    // 				callback(false, response);
                    // 			})
                    // 			.catch((error) => {
                    // 				callback(true, error);
                    // 			});
                    // 		},
                    // 	};
                    // },
                });
            }
        },
        loadPayment(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("payment", {
                        params: form,
                    })
                    .then(({ data }) => {
                        this.payments = data.results;
                        this.totalPayments = data.total_amount;
                    });
            }
        },
        loadWithdraw(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("withdraw", {
                        params: form,
                    })
                    .then(({ data }) => {
                        this.withdraws = data.results;
                        this.totalWithdraws = data.total_amount;
                    });
            }
        },
        loadBet(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("bet", {
                        params: {
                            page: form ? form.page : 1,
                            sort: form ? form.sort : "last",
                        },
                    })
                    .then(({ data }) => {
                        this.bets = data;
                    });
            }
        },
        loadBetRecord(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("bet/record", {
                        params: {
                            page: form ? form.page : 1,
                        },
                    })
                    .then(({ data }) => {
                        this.betRecords = data;
                    });
            }
        },
        loadTransactions(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("transaction", {
                        params: {
                            page: form ? form.page : 1,
                        },
                    })
                    .then(({ data }) => {
                        this.transactions = data;
                    });
            }
        },
        loadBanks() {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios.get("withdraw/banks").then(({ data }) => {
                    this.withdrawBanks = data;
                });
            }
        },
        createBet(form = null) {
            return axios.post("bet", form);
        },
        createPayment(form = null) {
            return axios.post("payment", form);
        },
        createWithdraw(form = null) {
            return axios.post("withdraw", form);
        },
        loadResult(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios.get("result", form).then(({ data, success }) => {
                    if (success) {
                        this.results = data;
                    }
                });
            }
        },
        loadOdds(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios.get("odds", form).then(({ data, success }) => {
                    if (success) {
                        this.odds = data;
                    }
                });
            }
        },
        loadHistories(form = null) {
            const auth = useAuthStore();
            if (auth.getToken) {
                return axios
                    .get("history", {
                        params: {
                            page: form ? form.page : 1,
                        },
                    })
                    .then(({ data, success }) => {
                        if (success) {
                            this.histories = data;
                        }
                    });
            }
        },
        removeError(index = null) {
            if (index) {
                this.errors.splice(index, 1);
            } else {
                this.errors = [];
            }
        },
        removeMessage(index = null) {
            if (index) {
                this.messages.splice(index, 1);
            } else {
                this.messages = [];
            }
        },
        addMessage({ message, success = false, errors = [] }) {
            if (this.messages.filter((a) => a.message === message).length > 0)
                return;
            this.messages.push({
                message,
                success,
                errors,
            });
        },
        resetMessage() {
            this.messages = [];
        },
    },
});
