import ListAgent from "@/views/Backend/Agent/List.vue";
import AddAgent from "@/views/Backend/Agent/Add.vue";
import RecordAgent from "@/views/Backend/Agent/Record.vue";
import Bank from "@/views/Backend/BankList.vue";
import Bet from "@/views/Backend/BetList.vue";
import Result from "@/views/Backend/Results.vue";
import Transaction from "@/views/Backend/Transaction.vue";
import Wallet from "@/views/Backend/Wallet.vue";

export default [
    {
        path: "/admin",
        name: "admin",
        meta: {
            admin: true,
            auth: true,
        },
        children: [
            {
                path: "bank",
                name: "admin_bank",
                component: Bank,
            },
            {
                path: "bet",
                name: "admin_bet",
                component: Bet,
            },
            {
                path: "result",
                name: "admin_result",
                component: Result,
            },
            {
                path: "wallet",
                name: "admin_wallet",
                component: Wallet,
            },
            {
                path: "transaction",
                name: "admin_transaction",
                component: Transaction,
            },
            {
                path: "agent",
                name: "admin_agent",
                component: ListAgent,
                children: [
                    {
                        path: "agent/add",
                        name: "admin_agent_add",
                        component: AddAgent,
                    },
                    {
                        path: "agent/record",
                        name: "admin_agent_record",
                        component: RecordAgent,
                    },
                ],
            },
        ],
    },
];
