<template>
    <div>
        <div class="flex flex-col gap-5 w-full text-white">
            <router-link
                :to="{ name: 'history' }"
                class="block py-2 text-xl rounded bg-orange-500 hover:bg-orange-600"
            >
                {{ $t("past_result") }}
            </router-link>
            <router-link
                :to="{ name: 'language' }"
                class="block py-2 text-xl rounded bg-orange-500 hover:bg-orange-600"
            >
                {{ $t("language") }}
            </router-link>
            <router-link
                :to="{ name: 'guide' }"
                class="block py-2 text-xl rounded bg-orange-500 hover:bg-orange-600"
            >
                {{ $t("how_to_play2") }}
            </router-link>
            <router-link
                :to="{ name: 'about' }"
                class="block py-2 text-xl rounded bg-orange-500 hover:bg-orange-600"
            >
                {{ $t("about") }}
            </router-link>
            <button
                @click="auth.logout()"
                class="block py-2 text-xl rounded bg-orange-500 hover:bg-orange-600"
            >
                {{ $t("logout") }}
            </button>
        </div>
    </div>
</template>
<script setup>
import { useAuthStore } from "@/store/index.js";

const auth = useAuthStore();
</script>
