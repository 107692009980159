<template>
    <div class="relative">
        <div class="text-2xl font-bold">{{ $t("about2") }}</div>
        <div class="text-xl">
            <div class="my-5">{{ $t("origin_of_super5_lottery") }}</div>
            <p>
                {{ $t("super5_lottery_pvt_ltd_super5_lottery_formerly_kno") }}
            </p>
        </div>
    </div>
</template>
<script setup></script>
