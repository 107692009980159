<template>
    <div>
        <div class="text-2xl font-bold">{{ $t("topup") }}</div>
        <div class="absolute top-2 left-0">
            <router-link
                :to="{ name: 'wallet' }"
                class="text-xl rounded-full bg-orange-600 p-2 block"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="w-8 h-8 text-gray-50"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M16 256a240 240 0 1 1 480 0A240 240 0 1 1 16 256zm496 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM234.3 146.3l-104 104c-3.1 3.1-3.1 8.2 0 11.3l104 104c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L155.3 264 376 264c4.4 0 8-3.6 8-8s-3.6-8-8-8l-220.7 0 90.3-90.3c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0z"
                    />
                </svg>
            </router-link>
        </div>
        <div class="text-xl text-red-300">{{ $t("notice") }}</div>
        <div class="flex flex-col text-red-300">
            <div>{{ $t("10_10_credit") }}</div>
            <div>{{ $t("100_100_credit") }}</div>
            <div>{{ $t("1000_1000_credit") }}</div>
        </div>
        <div class="flex gap-2 pt-5">
            <input
                type="number"
                :placeholder="$t('credit2')"
                class="p-2 rounded text-black flex-grow disabled:bg-gray-400 border border-gray-3"
                v-model="form.price"
            />
            <button
                @click="payNow"
                class="rounded text-center w-1/3 bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
            >
                {{ $t("topup_now") }}
            </button>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";
import { reactive, ref } from "vue";

const main = useMainStore();
let form = reactive({ price: 0 });
const loadingPayment = ref(false);
const payNow = () => {
    if (!form.price) {
        main.addMessage({
            message: "Please enter amount that you want to topup",
        });
        return;
    }
    if (loadingPayment.value) {
        main.addMessage({
            message: "Please wait, your payment request is on progress.",
        });
        return;
    }
    loadingPayment.value = true;
    main.createPayment(form).then((res) => {
        if (res.data.payment_url) {
            main.setModal("fpay", res.data.payment_url);
        }
        loadingPayment.value = false;
    });
};
</script>
