<template>
    <div>
        <div class="text-2xl font-bold">{{ $t("bet") }}</div>
        <div class="flex flex-col gap-5 pt-5 relative">
            <input
                type="tel"
                pattern="[0-9]{5}"
                :placeholder="$t('number_to_play')"
                class="p-2 rounded text-black w-full border border-gray-3"
                maxlength="5"
                @keypress="isNumber($event)"
                v-model="form.number"
            />
            <input
                type="number"
                :placeholder="$t('amount_to_play')"
                min="10"
                class="p-2 rounded text-black w-full border border-gray-3"
                v-model="form.amount"
            />
            <button
                @click="confirmPlay"
                class="rounded text-center w-full bg-green-600 hover:bg-green-700 font-bold py-2 text-white"
            >
                {{ $t("confirm") }}
            </button>
            <div class="py-2 text-red-500 font-bold">
                {{ $t("bet_notice") }}
            </div>
            <div
                class="py-2 text-center text-lg bg-gray-300 text-gray-800 font-bold"
            >
                {{ $t("top_up_your_credit") }}
                <router-link
                    :to="{ name: 'wallet' }"
                    class="text-blue-500 hover:text-blue-600"
                >
                    {{ $t("here") }}
                </router-link>
            </div>
        </div>
        <div class="flex flex-col font-bold text-center w-full my-5">
            <div v-if="main.getBets.total > 0" class="text-center font-bold">
                {{ main.getBets.current_page }} /
                {{ main.getBets.last_page }}
            </div>
            <div class="flex w-full">
                <div class="w-1/3">{{ $t("date") }}</div>
                <div class="w-1/3">{{ $t("number") }}</div>
                <div class="w-1/3">{{ $t("amount") }}</div>
            </div>
            <template v-if="main.getBets.total > 0">
                <div
                    v-for="(bet, key) in main.getBets.data"
                    :key="'bet-' + key"
                    class="flex w-full"
                >
                    <!--suppress AllyHtmlVueInspection -->
                    <div class="w-1/3">
                        {{ main.getDate(bet.bet_at) }}
                    </div>
                    <div class="w-1/3">
                        {{ bet.number }}
                    </div>
                    <div class="w-1/3">
                        {{ bet.amount }}
                    </div>
                </div>
            </template>
            <div v-else class="w-full text-red-300 my-2">
                {{ $t("no_new_bet_record") }}
                <router-link
                    :to="{ name: 'record' }"
                    class="text-blue-400 hover:text-blue-500"
                >
                    {{ $t("check_here") }}
                </router-link>
                {{ $t("for_old_record") }}
            </div>
        </div>
        <div v-if="main.getBets.total > 0" class="w-full flex gap-2">
            <button
                @click="prevPage"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    invisible: search.page <= 1,
                }"
            >
                <span v-if="search.page > 1">{{ $t("previous") }}</span>
                <span v-else></span>
            </button>
            <button
                @click="nextPage"
                class="rounded w-1/2 py-2 bg-orange-500 hover:bg-orange-600 text-white"
                :class="{
                    invisible: search.page >= main.bets.last_page,
                }"
            >
                <span v-if="search.page < main.bets.last_page">{{
                    $t("next")
                }}</span>
                <span v-else></span>
            </button>
        </div>
    </div>
</template>
<script setup>
import { inject, reactive } from "vue";
import { useAuthStore, useMainStore } from "@/store/index.js";
import { useI18n } from "vue-i18n";

const emitter = inject("emitter");
const main = useMainStore();
const auth = useAuthStore();
const { t } = useI18n();
const form = reactive({
    number: null,
    amount: null,
});
const confirmPlay = () => {
    if (form.amount < 10) {
        main.addMessage({ message: t("minimum_10"), success: false });
        return;
    }
    if (form.number.length > 5) {
        main.addMessage({ message: t("number_5_digit"), success: false });
        return;
    }
    main.createBet(form)
        .then(({ success }) => {
            if (success) {
                form.number = null;
                form.amount = null;
            }
        })
        .finally(() => {
            main.loadBet({ page: 1 });
            auth.me();
        });
};

let search = reactive({
    page: 1,
});
main.loadBet(search);
const nextPage = () => {
    if (search.page < main.getBets.last_page) {
        search.page++;
        main.loadBet(search);
        emitter.emit("toTop");
    }
};
const prevPage = () => {
    if (search.page > 1) {
        search.page--;
        main.loadBet(search);
        emitter.emit("toTop");
    }
};
const isNumber = (evt) => {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
    } else {
        return true;
    }
};
</script>
